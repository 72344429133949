<template>
  <!-- Sidebar for Large screens -->

  <!-- Services -->

  <div>
    <div
      v-if="serviceBtn"
      @click="toggleSidebar"
      class="fixed inset-0 bg-transparent z-30 font-barlow"
    ></div>
    <div
      :class="['sidebar', { 'show-sidebar': serviceBtn }]"
      class="hidden lg:fixed lg:top-0 lg:left-0 lg:right-0 lg:block bg-white transition-all ease-in-out duration-300 z-40 shadow-xl"
    >
      <div class="container mx-auto flex">
        <div class="flex gap-3">
          <div style="padding-top: 130px" class="flex gap-x-6">
            <div>
              <div class="text-base font-medium border-b-2 pl-5 cursor-default">
                <h1>{{ $t('navBarDropDown.generalTitle') }}</h1>
              </div>

              <router-link
                v-for="item in servicesGeneral.filter((x) => !x.hidden)"
                :hidden="item.hidden"
                :key="item.id"
                :to="`/services/${item.serviceRoute}`"
                @click="toggleBoolean"
              >
                <div
                  class="font-bold py-5 text-base hover:text-accent pl-5 rounded"
                >
                  <button class="w-fit text-start">
                    {{ item.name }}
                  </button>
                </div>
              </router-link>
            </div>

            <div>
              <div class="text-base font-medium border-b-2 pl-5 cursor-default">
                <h1>{{ $t('navBarDropDown.ourProductsTitle') }}</h1>
              </div>

              <div
                v-for="item in servicesProducts.filter((x) => !x.hidden)"
                :key="item.id"
                class="py-5 text-base hover:text-accent pl-5 rounded"
              >
                <RouterLink
                  :to="`/services/${item.serviceRoute}`"
                  @click="toggleBoolean"
                >
                  <button class="w-fit text-start">
                    <div>
                      <span class="py-2 font-bold">
                        <b>{{ item.name }} </b>
                        <span
                          v-if="item.featuring"
                          class="px-2 bg-accent text-white rounded"
                          >{{ $t('navBarDropDown.popularTitle') }}</span
                        >
                      </span>
                      <p class="text-sm py-2">
                        {{ item.description }}
                      </p>
                    </div>
                  </button>
                </RouterLink>
              </div>
            </div>
          </div>

          <div class="bg-gray-100 h-full w-full"></div>
        </div>
      </div>
      <div class="h-10 border-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    serviceBtn: Boolean
  },
  data() {
    return {
      locale: localStorage.getItem('locale') || 'DE'
    }
  },
  computed: {
    servicesGeneral() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).navBarDropDown
        .general
    },
    servicesProducts() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).navBarDropDown
        .ourProducts
    }
  },
  methods: {
    toggleBoolean() {
      const newValue = !this.serviceBtn
      this.$emit('update-service', newValue)
    },
    toggleSidebar() {
      this.$emit('update-service', false)
    }
  }
}
</script>

<style scoped>
.sidebar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}
.show-sidebar {
  transform: translateY(0);
}
</style>
