<template>
  <!-- Hero Header -->
  <div>
    <div class="blogpost transition-all duration-1000 transform translate-y-20 opacity-0 slide-up-element cursor-default font-barlow">
      <!-- Left Content Container -->
      <div class="flex flex-col bg-[#fff] pl-5 text-justify">
        <div class="flex justify-center items-center gap-2 py-5">
          <router-link :to="`/blog-post/${blogDetails.blogRoute}`" class="w-fit">
            <div class="h-[11.6px] w-[21.6px] bg-accent border border-accent hover:bg-white transition-all duration-300 rounded">
            </div>
          </router-link>
        </div>

        <div class="blog-title font-extrabold text-[#141733] text-center break-words">
          {{ this.blogDetails.title }}
        </div>

        <div class="py-5">
          <p class="text-gray-700 text-center">{{ this.blogDetails.date }}</p>
        </div>

        <div>
          <img :src="getImage(this.blogDetails.blogImage)" :alt="this.blogDetails.title" />
        </div>
        <div class="flex flex-col gap-y-5 my-10 text-gray-800 text-start text-[15px] font-normal leading-[1.6em]" v-html="sanitizedHtml">
        </div>

        <div class="flex justify-center items-center gap-5">
          <div class="border-2 rounded-full bg-white w-fit p-3 hover:bg-gray-200">
            <a href="https://www.linkedin.com/company/wx-one/?originalSubdomain=de">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0 0 30 30"
              >
                <path
                  d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"
                ></path>
              </svg>
            </a>
          </div>

          <div class="border-2 rounded-full bg-white w-fit p-3 hover:bg-gray-200">
            <a href="https://x.com/i/flow/login?redirect_after_login=%2Fwizardtales">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0 0 30 30"
              >
                <path
                  d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <!-- Right Fixed Sidebar -->
       <div class="relative-wrapper">
         <div
           class="sticky top-[20px] side-content-position"
         >
           <div class="flex flex-col gap-y-10">
             <!-- Additional Services Section -->
             <div class="shadow-xl flex flex-col p-5 w-[95%] mx-auto">
               <div class="text-xl font-black p-5 underline">{{ $t('blogPage.recentPosts') }}</div>
               <div class="flex flex-col gap-y-5 p-5">
                 <div v-for="item in dataList" :key="item.id">
                   <router-link :to="`/blog-post/${item.blogRoute}`" class="w-fit">
                     <button
                       class="text-start font-normal text-lg hover:text-accent hover:transition-all"
                     >
                       {{ item.title }}
                     </button>
                   </router-link>
                 </div>
               </div>
             </div>

             <!-- Categories -->
             <div class="flex flex-col shadow-xl gap-y-10 p-10 w-[95%] mx-auto">
               <div class="text-xl font-bold underline">{{ $t('blogPage.catgories') }}</div>

               <div class="bg-gray-200 px-3 py-2">
                 {{ $t('blogPage.noItemsFound') }}
               </div>
             </div>
           </div>
         </div>
       </div>
    </div>
  </div>
  <ScrollToTop />
</template>

<script>
import ScrollToTop from '@/components/ScrollToTop.vue';
import DOMPurify from 'dompurify';
export default {
  data() {
    return {
      recentPost: [],
      blogDetails: {},
      lastScrollY: 0
    }
  },
  components: {
    ScrollToTop,
  },
  created() {
    document.title = this.blogDetails.browserTabTitle
    this.blogData();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    // Initial check
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    blogData() {
      this.blogDetails = this.dataList.find(blog => blog.blogRoute === this.$route.params.name);
      sessionStorage.setItem('blogOtherLanguage', this.blogDetails.otherLanguageRoute)
    },
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    },
    handleScroll() {
    const elements = document.querySelectorAll('.slide-up-element');

    elements.forEach((el) => {
      const rect = el.getBoundingClientRect();
      const triggerPoint = window.innerHeight * 0.8;

      if (rect.top < triggerPoint) {
        el.classList.add('slide-up-visible');
      }
    });
  }
  },
  computed: {
    dataList() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).blogPage.blogsList;
    },
    sanitizedHtml() {
      return DOMPurify.sanitize(this.blogDetails.blogDescription);
    }
  },
  watch: {
    '$route.params.name': {
      handler(newId) {
        if (newId) {
          this.blogData();
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="postcss">
.blogpost {
  @apply xl:container xl:mx-auto flex flex-col lg:flex-row xl:flex-row mt-32 mb-10 p-5 gap-5;
  will-change: transform, opacity;
}

.blog-title {
  @apply text-[45px] md:text-[65px] leading-none;
}

.relative-wrapper {
  @apply relative flex-1;
}

.side-content-position {
  @apply lg:w-[320px] xl:w-[380px] min-h-[100px] overflow-auto text-lg p-5;
}

.main {
  background-color: #f8f8f8;
}

.mains {
  width: 65%;
  height: 100vh;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-left: 10px;
}

.sidebar {
  width: 35%;
  height: 100%;
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  font-size: 15px;
}

.slide-up-element {
  opacity: 0;
  transform: translateY(40px);
  transition: all 1s ease-out;
  visibility: hidden;
}

.slide-up-element.slide-up-visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
</style>
