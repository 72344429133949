<template>
  <div>
    <HeroHeader />
    <Section2Component />
    <Section3Component />
    <Section4Component />
    <Section5Component />
    <Section6Component />
    <ScrollToTop />
  </div>
</template>

<script>
import HeroHeader from '@/components/BackupRecovery/HeroHeader.vue';
import Section2Component from '@/components/BackupRecovery/Section2Component.vue';
import Section3Component from '@/components/BackupRecovery/Section3Component.vue';
import Section4Component from '@/components/BackupRecovery/Section4Component.vue';
import Section5Component from '@/components/BackupRecovery/Section5Component.vue';
import Section6Component from '@/components/BackupRecovery/Section6Component.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';
import { nextTick } from 'vue';
export default {
  components: {
    HeroHeader,
    Section2Component,
    Section3Component,
    Section4Component,
    Section5Component,
    Section6Component,
    ScrollToTop,
  },
  created() {
    document.title = 'Backup & Recovery in Goldstandard'
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0,0);
    })
  },
}
</script>

<style scoped></style>
