<template>
  <div
    class="flex uppercase justify-center items-center h-96 main text-4xl lg:text-5xl font-black pt-16 lg:pt-20 font-barlow"
  >
  {{ $t('contactPage.title') }}
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
</style>
