<template>
  <div class="container mx-auto pt-10 pb-24 px-5 font-barlow">
    <div class="flex flex-col justify-center items-center text-center gap-y-10">
      <h1 class="always-ready">{{ $t('servicePage.alwaysReady') }} <br />{{ $t('servicePage.giveUrBusiness') }} </h1>
      <p class="always-ready-content">
        {{ $t('servicePage.focus1') }} <br> {{ $t('servicePage.focus2') }}
      </p>
      <router-link :to="'/contact-us'" class="w-fit">
        <button class="contact-btn">{{ $t('servicePage.appointment') }}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="postcss">
.always-ready {
  @apply text-[22px] md:text-[36px] xl:text-[45px] font-black;
}

.always-ready-content {
  @apply text-gray-700 text-[16px] sm:text-[16px] md:text-[18px] xl:text-[18px];
}

.contact-btn {
  @apply text-[17.5px] bg-accent text-white font-bold h-[60px] w-[360px] rounded-3xl border-2 border-accent hover:text-accent hover:bg-white transition-all duration-300;
}
</style>
