<template>
  <HeroHeader />
  <ScrollAnimation>
    <ContactForm />
  </ScrollAnimation>
</template>

<script>
import HeroHeader from '../components/ContactUs/HeroHeader.vue'
import ContactForm from '../components/ContactUs/ContactForm.vue'
import { nextTick } from 'vue';
import ScrollAnimation from '@/components/ScrollAnimation.vue'

export default {
  components: {
    HeroHeader,
    ContactForm,
    ScrollAnimation
  },
  data() {
    return {}
  },
  created() {
    document.title = 'Contact Us — WX-ONE'
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0,0);
    })
  },
}
</script>

<style scoped></style>
