<template>
  <div class="scroll-smooth">
    <HeroHeader />
    <ScrollAnimation>
      <AboutWx1 />
    </ScrollAnimation>
    <ScrollAnimation>
      <Section3Component :hidden="true" />
    </ScrollAnimation>
    <ScrollAnimation>
      <Section4Component :hidden="true" />
    </ScrollAnimation>
    <ScrollAnimation>
      <Section5Component />
    </ScrollAnimation>
    <ScrollAnimation>
      <Section6Component />
    </ScrollAnimation>

    <ScrollToTop />
  </div>
</template>

<script>
import HeroHeader from '@/components/AboutUs/HeroHeader.vue'
import AboutWx1 from '@/components/AboutUs/AboutWx1.vue'
import Section3Component from '@/components/AboutUs/Section3Component.vue'
import Section4Component from '@/components/AboutUs/Section4Component.vue'
import Section5Component from '@/components/AboutUs/Section5Component.vue'
import Section6Component from '@/components/AboutUs/Section6Component.vue'
import { nextTick } from 'vue'
import ScrollAnimation from '@/components/ScrollAnimation.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'

export default {
  components: {
    HeroHeader,
    AboutWx1,
    Section3Component,
    Section4Component,
    Section5Component,
    Section6Component,
    ScrollAnimation,
    ScrollToTop,
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0,0);
    })
  },
}
</script>

<style lang="scss" scoped></style>
