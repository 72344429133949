<template>
  <nav
    class="py-6 lg:pt-4 lg:pb-2 fixed top-0 left-0 right-0 shadow-lg z-50 bg-white font-barlow"
  >
    <div class="lg:container lg:mx-auto flex justify-between w-full">
      <div class="flex justify-between items-center w-full">
        <!-- Company Logo -->
        <div class="w-fit flex justify-center items-center xl:pl-10">
          <router-link :to="'/'" class="brand-2 w-nav-brand w-fit">
            <img
              src="../assets/images/wx-one_official_header.svg"
              alt="wzrdTales"
              class="img-size"
            />
          </router-link>

          <!-- Sections for large screens -->
          <div
            class="hidden lg:flex lg:justify-center lg:items-center max-w-fit"
          >
            <button
              class="text-nowrap flex justify-center items-center p-5 w-fit"
              @click=";(serviceBtn = !serviceBtn), (AboutBtn = false)"
            >
              <h1 class="nav-title hover:text-accent">
                {{ $t('navBar.servicesTitle') }}
              </h1>
              <svg
                :class="
                  serviceBtn
                    ? '-rotate-180 transition-all duration-300'
                    : '-rotate-0 transition-all duration-300'
                "
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M7 10L12 15L17 10"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </button>
            <button
              class="text-nowrap flex justify-center items-center p-5 w-fit"
              @click=";(AboutBtn = !AboutBtn), (serviceBtn = false)"
            >
              <h1 class="nav-title hover:text-accent">
                {{ $t('navBar.aboutTitle') }}
              </h1>
              <svg
                :class="
                  AboutBtn
                    ? '-rotate-180 transition-all duration-300'
                    : '-rotate-0 transition-all duration-300'
                "
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M7 10L12 15L17 10"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>

        <div class="flex items-center gap-2 w-fit">
          <!-- Language selection -->
          <div class="flex space-x-1 p-1 rounded-md justify-end pr-6">
            <label
              v-for="option in options"
              :key="option.value"
              class="flex items-center justify-center w-8 h-8 rounded-2xl cursor-pointer bg-gray-100 p-1 drop-shadow-md hover:text-accent"
              :class="{
                'border border-accent drop-shadow-md':
                  selectedLanguage === option.value
              }"
            >
              <input
                type="radio"
                :value="option.value"
                v-model="selectedLanguage"
                @change="handleLanguageChange"
                class="sr-only"
              />
              <span>{{ option.label }}</span>
            </label>
          </div>

          <!-- Contact Now -->
          <div class="hidden lg:block w-fit text-nowrap border-l-2 pl-7">
            <RouterLink :to="'/contact-us'" class="translate-x-5">
              <button
                class="bg-accent w-[202px] h-[60px] px-[15px] py-[9px] rounded-3xl text-lg font-bold text-white hover:bg-accent"
              >
                {{ $t('navBar.contactUsTitle') }}
              </button>
            </RouterLink>
          </div>

          <!-- hamburger menu -->
          <div class="max-w-fit lg:hidden">
            <button class="mr-8" @click="isSidebarOpen = !isSidebarOpen">
              <div v-if="isSidebarOpen">
                <img
                  class="h-8 w-8"
                  src="../assets/images/close-pop-up.svg"
                  alt=""
                  />
                </div>
                <div v-else>
                  <img
                  class="h-8 w-8"
                  src="../assets/images/open-pop-up.svg"
                  alt=""
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <SidebarSmall
    :isSidebarOpen="isSidebarOpen"
    @update-sidebar="handleSmallSidebar"
  />

  <SidebarLarge :serviceBtn="serviceBtn" @update-service="handleServiceBtn" />

  <SidebarLarge2 :AboutBtn="AboutBtn" @update-about="handleAboutBtn" />
</template>

<script>
import SidebarLarge from './Sidebar/SidebarLarge.vue'
import SidebarLarge2 from './Sidebar/SidebarLarge2.vue'
import SidebarSmall from './Sidebar/SidebarSmall.vue'

export default {
  name: 'navBar',
  components: {
    SidebarLarge,
    SidebarSmall,
    SidebarLarge2
  },
  data() {
    return {
      isSidebarOpen: false,
      serviceBtn: false,
      AboutBtn: false,
      options: [
        { label: 'EN', value: 'EN' },
        { label: 'DE', value: 'DE' }
      ],
      selectedLanguage: localStorage.getItem('locale') || 'DE'
    }
  },
  created() {
    if (localStorage.getItem('locale') === null) {
      localStorage.setItem('locale', 'DE')
    }
    this.selectedLanguage = localStorage.getItem('locale') || 'DE'
    this.$i18n.locale = this.selectedLanguage
  },
  methods: {
    handleLanguageChange() {
      localStorage.setItem('locale', this.selectedLanguage)
      this.$i18n.locale = this.selectedLanguage
    },
    handleAboutBtn(newValue) {
      this.AboutBtn = newValue
    },
    handleServiceBtn(newValue) {
      this.serviceBtn = newValue
    },
    handleSmallSidebar(newValue) {
      this.isSidebarOpen = newValue
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) {
          this.selectedLanguage = localStorage.getItem('locale')
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.slide-down-enter {
  transform: translateY(-100%);
}
.slide-down-leave-to {
  transform: translateY(-100%);
}
.nav-title {
  color: #141733;
  margin-right: 6px;
  font-size: 17.5px;
  font-weight: 800;
}

.img-size {
  display: inline-block;
  width: 150px;
  aspect-ratio: auto 150 / 45;
  height: 45px;
}

@media screen and (max-width: 1366px) {
  .img-size {
    display: inline-block;
    width: 200px;
    aspect-ratio: auto 200 / 45;
    height: 45px;
  }
}

.brand-2 {
  flex: none;
  align-items: center;
  margin-right: 32px;
  display: flex;
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}
</style>
