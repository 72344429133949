<template>
  <div id="focus" class="sm:px-[15px] md:container md:mx-auto lg:px-[70px] xl:container xl:mx-auto xl:px-[170px] py-10 smooth-scroll px-[20px] cursor-default font-barlow">
    <div class="flex flex-col text-center gap-y-5">
      <h2 class="header font-extrabold">{{ $t('backupRecoveryPage.someAreas') }}</h2>
      <p class="header-descripton text-gray-500">
        {{ $t('backupRecoveryPage.designCustomised') }}
      </p>
    </div>

    <div class="py-10">
      <div
        class="grid grid-rows-3 grid-cols-1 md:grid-rows-2 md:grid-cols-2 lg:grid-rows-1 lg:grid-cols-3 gap-10"
      >
        <div
          v-for="item in data"
          :key="item.id"
          class="text-center shadow-xl p-7 border border-[#8e8f9a] rounded-md"
        >
          <img :src="getImage(item.imageLink)" alt="" class="" />
          <div class="flex flex-col gap-y-3">
            <h2 class="title font-bold leading-tight break-words">
              {{ item.title }}
            </h2>
            <p class="description text-gray-700">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section5Component',
  computed: {
    data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).backupRecoveryPage.areas;
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  }
}
</script>

<style scoped lang="postcss">
.header {
  @apply text-[20px] sm:text-[32px] md:text-[36px] xl:text-[45px];
}

.header-descripton {
  @apply text-[16px] sm:text-[16px] md:text-[16px] xl:text-[16px];
}

.title {
  @apply text-[20px] sm:text-[26px] md:text-[26px] xl:text-[35px];
}

.description {
  @apply text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px];
}
</style>
