<template>
  <div class="service py-10 font-barlow">
    <div class="service-cards">
      <div
        v-for="service in services"
        :hidden="service.hidden"
        :key="service.id"
        class="flex flex-col justify-center items-center text-center"
        :class="service.hidden ? 'hidden' : ''"
      >
        <div
          class="main relative transform-gpu transition-transform duration-300 perspective"
          ref="cards"
          @mousemove="handleMouseMove"
          @mouseleave="handleMouseLeave"
          :data-card-id="service.id"
        >
          <img :src="getImage(service.imageLink)" />
          <div
            class="absolute top-0 flex flex-col items-center justify-center opacity-85 h-full w-full card-color transition-all duration-500 ease"
          >
            <button
              class="px-auto ele1 learn-more-btn text-grey-600 font-medium hover:text-accent hover:underline"
              @click="gotoServiceDetails(service)"
            >
            <div class="flex items-center justify-center gap-x-1">
              <span class="w-fit">
                {{ $t('servicePage.learnMoreAbout') }}
              </span>
              <span class="w-fit">
                <img
                  src="../../assets/images/caret-right-solid.svg"
                  alt=""
                  class="h-[20px]"
                />
              </span>
              </div>
            </button>
            <div class="ele2 service-btn px-8 font-bold text-grey-800">
              <div>
                {{ service.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tiltCards: {}
    }
  },
  computed: {
    services() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).servicePage.services
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    },
    gotoServiceDetails(service) {
      this.$router.push(`/services/${service.serviceRoute}`)
    },
    handleMouseMove(event) {
      const card = event.currentTarget
      const rect = card.getBoundingClientRect()

      // Calculate mouse position relative to card center
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      // Calculate the percentage position from the center
      const centerX = rect.width / 2
      const centerY = rect.height / 2

      // Calculate tilt angles (lower values for more subtle tilt)
      const tiltX = ((y - centerY) / centerY) * 10
      const tiltY = ((centerX - x) / centerX) * 10

      // Apply the transformation
      card.style.transform = `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg) scale(1.02)`

      // Add enhanced shadow effect
      card.style.boxShadow = `
        0 12px 24px rgba(0,0,0,0.2),
        ${tiltY * 0.7}px ${tiltX * -0.7}px 12px rgba(0,0,0,0.15)
      `
    },
    handleMouseLeave(event) {
      const card = event.currentTarget
      // Reset transform and shadow when mouse leaves
      card.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) scale(1)'
      card.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)'
      card.style.transition = 'transform 0.5s ease-out, box-shadow 0.5s ease-out'
    }
  }
}
</script>

<style scoped lang="postcss">
.service {
  @apply p-4 xl:container mx-auto;
}

.service-cards {
  @apply grid grid-rows-1 grid-cols-1 md:grid-rows-2 md:grid-cols-2 lg:grid-rows-2 gap-5;
}

.learn-more-btn {
  @apply text-[17.5px] md:text-[17.5px];
}

.service-btn {
  @apply text-[24px] md:text-[24px] xl:text-[27px];
}

.card-color {
  background-color: rgb(175, 196, 210);
  border: 1px #000;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.ele1,
.ele2 {
  transition: transform 0.5s ease, opcaity 0.5s ease;
}

.main {
  border-radius: 10px;
}

.main:hover .ele1 {
  transform: translateY(40px);
}

.main:hover .ele2 {
  transform: translateY(-40px);
  opacity: 1;
}

.perspective {
  transform-style: preserve-3d;
  will-change: transform;
}
</style>
