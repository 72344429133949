// i18n.js
import { createI18n } from 'vue-i18n';
import EN from './locale/en.json';
import DE from './locale/de.json';

const savedLocale = localStorage.getItem('locale') || 'DE';

const i18n = createI18n({
  locale: savedLocale,
  fallbackLocale: 'DE',
  messages: { EN, DE },
  allowObject: true,
});

export default i18n;
