<template>
  <div class="sm:px-[15px] md:container md:mx-auto lg:px-[70px] xl:container xl:mx-auto xl:px-[170px] px-[20px] cursor-default font-barlow">
    <div class="paragraph font-bold">
        <h3>
          {{ $t('backupRecoveryPage.weOffer') }}
        </h3>
        <h3>
          {{ $t('backupRecoveryPage.contactUs') }}
        </h3>
    </div>

    <div class="flex flex-col lg:flex-row justify-around items-center gap-5 text-center py-11">
        <div class="w-fit xl:w-[400px]">
            <h3 class="contact-text font-extrabold">
              {{ $t('backupRecoveryPage.happy') }}
            </h3>
        </div>
        <div class="w-fit">
          <router-link :to="'/contact-us'">
            <button class="bg-accent text-white border-4 border-accent hover:text-accent hover:bg-white h-[50px] w-[300px] rounded-3xl">
              {{ $t('backupRecoveryPage.book') }}
            </button>
          </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="postcss">
.paragraph {
  @apply text-[12px] md:text-[18px];
}

.contact-text {
  @apply text-[20px] sm:text-[26px] xl:text-[35px];
}
</style>
