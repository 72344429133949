<template>
  <section class="bg-white">
    <div
      class="grid grid-cols-1 lg:grid-cols-2 gap-12 py-16 px-4 max-w-7xl mx-auto"
    >
      <!-- Left column with title -->
      <div class="lg:col-span-1 px-8 xl:px-16 lg:py-16 uppercase">
        <h2 class="text-4xl font-bold">{{ $t('homepage.section4.title') }}</h2>
      </div>

      <!-- Right column with paragraphs -->
      <div class="lg:col-span-1 lg:py-16 flex flex-col gap-6">
        <p class="px-8 xl:px-16">{{ $t('homepage.section4.paragraph[0]') }}</p>
        <p class="px-8 xl:px-16">{{ $t('homepage.section4.paragraph[1]') }}</p>
        <p class="px-8 xl:px-16">{{ $t('homepage.section4.paragraph[2]') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section4Component'
}
</script>

<style scoped lang="postcss"></style>
