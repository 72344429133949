<template>
  <section class="bg-primary text-white py-16">
    <div class="container mx-auto px-4 text-center mb-16">
      <h2 class="text-4xl font-bold mb-6 uppercase">
        {{ $t('homepage.section5.title') }}
      </h2>
      <p class="max-w-4xl mx-auto">{{ $t('homepage.section5.description') }}</p>
    </div>

    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        <div class="text-center">
          <div class="flex justify-center mb-8">
            <span
              role="img"
              :aria-label="$t('homepage.section5.services[0].title')"
              ><i
                class="fas text-accent text-4xl"
                :class="$t('homepage.section5.services[0].icon')"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <h3 class="text-2xl text-accent font-semibold mb-3">
            {{ $t('homepage.section5.services[0].title') }}
          </h3>
          <p>{{ $t('homepage.section5.services[0].description') }}</p>
        </div>

        <div class="text-center">
          <div class="flex justify-center mb-8">
            <span
              role="img"
              :aria-label="$t('homepage.section5.services[1].title')"
              ><i
                class="fas text-accent text-4xl"
                :class="$t('homepage.section5.services[1].icon')"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <h3 class="text-2xl text-accent font-semibold mb-3">
            {{ $t('homepage.section5.services[1].title') }}
          </h3>
          <p>{{ $t('homepage.section5.services[1].description') }}</p>
        </div>

        <div class="text-center">
          <div class="flex justify-center mb-8">
            <span
              role="img"
              :aria-label="$t('homepage.section5.services[2].title')"
              ><i
                class="fas text-accent text-4xl"
                :class="$t('homepage.section5.services[2].icon')"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <h3 class="text-2xl text-accent font-semibold mb-3">
            {{ $t('homepage.section5.services[2].title') }}
          </h3>
          <p>{{ $t('homepage.section5.services[2].description') }}</p>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div class="text-center">
          <div class="flex justify-center mb-8">
            <span
              role="img"
              :aria-label="$t('homepage.section5.services[3].title')"
              ><i
                class="fas text-accent text-4xl"
                :class="$t('homepage.section5.services[3].icon')"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <h3 class="text-2xl text-accent font-semibold mb-3">
            {{ $t('homepage.section5.services[3].title') }}
          </h3>
          <p>{{ $t('homepage.section5.services[3].description') }}</p>
        </div>

        <div class="text-center">
          <div class="flex justify-center mb-8">
            <span
              role="img"
              :aria-label="$t('homepage.section5.services[4].title')"
              ><i
                class="fas text-accent text-4xl"
                :class="$t('homepage.section5.services[4].icon')"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <h3 class="text-2xl text-accent font-semibold mb-3">
            {{ $t('homepage.section5.services[4].title') }}
          </h3>
          <p>{{ $t('homepage.section5.services[4].description') }}</p>
        </div>

        <div class="text-center">
          <div class="flex justify-center mb-8">
            <span
              role="img"
              :aria-label="$t('homepage.section5.services[5].title')"
              ><i
                class="fas text-accent text-4xl"
                :class="$t('homepage.section5.services[5].icon')"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <h3 class="text-2xl text-accent font-semibold mb-3">
            {{ $t('homepage.section5.services[5].title') }}
          </h3>
          <p>{{ $t('homepage.section5.services[5].description') }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section5Component'
}
</script>

<style scoped lang="postcss">
</style>
