<template>
  <!-- Sidebar for small and medium screens -->

  <!-- <transition name="slide-down"> -->
    <div
    :class="['sidebar', { 'show-sidebar': isSidebarOpen }]"
      class="fixed lg:hidden top-0 left-0 w-full h-[90vh] bg-white text-black z-40 flex flex-col items-center transition-all duration-300 sidebar-content shadow-xl font-barlow"
    >
      <div
        class="overflow-y-auto w-full h-full"
        style="padding-top: calc(100vh - 87vh)"
      >
        <div class="text-xl font-bold flex flex-col gap-y-5 w-full p-3">
          <div class="border-b-2">
            <button
              class="py-3 flex justify-between items-center container mx-auto"
              @click="serviceBtn = !serviceBtn"
            >
              <h1 class="w-fit">{{ $t('navBar.servicesTitle') }}</h1>
              <svg
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-fit"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M7 10L12 15L17 10"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </button>

            <div v-if="serviceBtn">
              <div class="py-5">
                <div class="container mx-auto">
                  <div class="text-base font-medium border-b-2 pl-5">
                    <h1>{{ $t('navBarDropDown.generalTitle') }}</h1>
                  </div>

                  <router-link
                  v-for="item in servicesGeneral"
                  :hidden="item.hidden"
                  :key="item.id"
                    :to="`/services/${item.serviceRoute}`"
                    @click="toggleBoolean"
                  >
                  <div
                    class="py-5 p-5 text-base"
                  >
                      <button class="w-fit font-medium">
                        {{ item.name }}
                      </button>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="py-5">
                <div class="hidden container mx-auto">
                  <div class="text-base font-medium border-b-2 pl-5">
                    <h1>{{ $t('navBarDropDown.ourProductsTitle') }}</h1>
                  </div>

                  <div class="py-5 p-5 text-base">
                    <router-link
                      :to="`/backup-recovery`"
                      @click="toggleBoolean"
                    >
                      <button class="w-fit text-start">
                        <div>
                          <span class="py-2 font-medium">
                            <b>{{ servicesProducts[0].name }} </b>
                            <span
                              v-if="servicesProducts[0].featuring"
                              class="px-2 bg-accent text-white rounded"
                              >{{ $t('navBarDropDown.popularTitle') }}</span
                            >
                          </span>
                          <p class="text-sm text-gray-500 py-2">
                            {{ servicesProducts[0].description }}
                          </p>
                        </div>
                      </button>
                    </router-link>
                  </div>
                  <div
                    v-for="item in servicesProducts.slice(1)"
                    :key="item.id"
                    class="py-5 p-5 text-base"
                  >
                    <router-link
                      :to="`/services/${item.serviceRoute}`"
                      @click="toggleBoolean"
                    >
                      <button class="w-fit text-start">
                        <div>
                          <span class="py-2 font-medium">
                            <b>{{ item.name }} </b>
                            <span
                              v-if="item.featuring"
                              class="px-2 bg-accent text-white rounded"
                              >{{ $t('navBarDropDown.popularTitle') }}</span
                            >
                          </span>
                          <p class="text-sm text-gray-500 py-2">
                            {{ item.description }}
                          </p>
                        </div>
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- About Section!!! -->

          <div class="border-b-2">
            <button
              class="py-3 flex justify-between items-center container mx-auto"
              @click="AboutBtn = !AboutBtn"
            >
              <h1 class="w-fit">{{ $t('navBar.aboutTitle') }}</h1>
              <svg
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-fit"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M7 10L12 15L17 10"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </button>

            <div v-if="AboutBtn">
              <div class="py-5">
                <div class="container mx-auto">
                  <div class="text-base font-medium border-b-2 pl-5">
                    <h1>{{ $t('navBarDropDown.informationTitle') }}</h1>
                  </div>

                  <router-link
                  v-for="item in aboutInformation"
                  :key="item.id"
                  :to="item.link" @click="toggleBoolean">
                  <div
                    class="py-5 p-5 text-base"
                  >
                      <button class="w-fit text-start">
                        <div>
                          <span class="py-2 font-medium">
                            <b>{{ item.name }} </b>
                          </span>
                          <p class="text-sm text-gray-500 py-2">
                            {{ item.description }}
                          </p>
                        </div>
                      </button>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="py-5">
                <div class="container mx-auto">
                  <div class="text-base font-medium border-b-2 pl-5">
                    <h1>{{ $t('navBarDropDown.weEverywhereTitle') }}</h1>
                  </div>

                  <div
                    v-for="item in aboutWeEveryWhere"
                    :key="item.id"
                    class="py-5 p-5 text-base"
                  >
                    <a
                      href="https://www.linkedin.com/company/wx-one/"
                      @click="toggleBoolean"
                    >
                      <button class="w-fit text-start">
                        <div>
                          <span class="py-2 font-medium">
                            <b>{{ item.name }} </b>
                          </span>
                          <p class="text-sm text-gray-400 py-2">
                            {{ item.description }}
                          </p>
                        </div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div class="py-5 bg-gray-100">
                <div class="container mx-auto">
                  <div class="text-base font-medium border-b-2">
                    <h1>{{ $t('navBarDropDown.contentFromOurHouseTitle') }}</h1>
                  </div>

                  <div
                    v-for="item in aboutBlogPosts"
                    :key="item.id"
                    class="font-medium py-5 text-base"
                  >
                    <router-link
                      :to="`/blog-post/${item.blogRoute}`"
                      @click="toggleBoolean"
                    >
                      <div
                        class="flex gap-3 text-start p-4 hover:shadow-lg hover:cursor-pointer bg-white w-full"
                      >
                      <div class="w-fit">
                      <img
                        :src="getImage(item.blogImage)"
                        class="h-16 w-16 object-top object-cover"
                        alt=""
                      />
                    </div>
                        <div>
                          <span class="text-gray-400 text-sm font-semibold">{{
                            $t('navBarDropDown.blogTitle')
                          }}</span>
                          <p
                            class="text-xs py-2 flex-wrap text-[#141733]"
                          >
                            {{ item.title }}
                          </p>
                          <div class="py-2 flex items-center justify-between">
                        <div class="text-gray-500 text-base font-semibold group-hover:text-accent">{{
                          $t('navBarDropDown.readMoreTitle')
                        }}</div>
                        <div class="w-fit">
                          <svg class="h-3 w-3" viewBox="0 0 14 11" preserveAspectRatio="xMinYMin" style="fill: #646f79"><path fill-rule="evenodd" d="M9.827.144L9.019.86l3.256 3.738H0V5.69h12.275L9.02 9.427l.808.717 4.173-5-4.173-5z"></path></svg>
                        </div>
                      </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Contact Now -->
          <div
            class="text-nowrap py-3 container mx-auto"
            @click="toggleBoolean"
          >
            <router-link :to="'/contact-us'">
              <button
                class="w-full bg-accent px-5 py-2 text-xl font-bold text-white rounded-lg hover:text-accent hover:bg-white hover:border-accent"
              >
                {{ $t('navBar.contactUsTitle') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  <!-- </transition> -->
</template>

<script>
export default {
  name: 'navBar',
  props: {
    isSidebarOpen: Boolean
  },
  computed: {
    servicesGeneral() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.general
    },
    servicesProducts() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.ourProducts
    },
    aboutInformation() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.information
    },
    aboutWeEveryWhere() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.weEverywhere
    },
    aboutBlogPosts() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.blogPosts
    }
  },
  data() {
    return {
      serviceBtn: false,
      AboutBtn: false,
    }
  },
  methods: {
    toggleBoolean() {
      const newValue = !this.isSidebarOpen
      this.serviceBtn = false
      this.AboutBtn = false
      this.$emit('update-sidebar', newValue)
    },
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  },
  watch: {
    isSidebarOpen(newValue) {
      document.body.classList.toggle('no-scroll', newValue);
    }
  }
}
</script>

<style scoped>
.sidebar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}
.show-sidebar {
  transform: translateY(0);
}
</style>
