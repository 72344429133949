<template>
  <div id="goals" class="container mx-auto py-10 px-[30px] cursor-default font-barlow">
    <div
      class="grid grid-rows-3 grid-cols-1 md:grid-rows-2 md:grid-cols-2 lg:grid-rows-1 lg:grid-cols-3 xl:grid-rows-1 xl:grid-cols-3 gap-5"
    >
      <div
        v-for="item in data"
        :key="item.id"
        class="card shadow-2xl max-w-content max-h-content xl:w-[370px] xl:h-[600px] mx-auto bg-white"
      >
        <img
          :src="getImage(item.imageLink)"
          alt=""
          class="object-cover h-[300px]"
        />
        <div class="flex flex-col gap-y-5 p-6 card-data">
          <h1 class="title font-extrabold pt-5 leading-none">{{ item.title }}</h1>
          <p class="description text-gray-500 leading-snug">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section4Component',
  computed: {
    data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).aboutus.mission
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  }
}
</script>

<style scoped lang="postcss">
@media screen and (min-width: 1280px) {
  .card:nth-child(1) {
    transform: translateX(60px);
    height: 560px;
    margin-top: 5%;
  }
  .card:nth-child(3) {
    transform: translateX(-60px);
    height: 560px;
    margin-top: 5%;
  }
  .card:nth-child(2) {
    z-index: 20;
  }
  .card-data:nth-child(1) {
    padding-right: 20px;
  }
  .card-data:nth-child(3) {
    padding-left: 20px;
  }
}

.title {
  @apply text-[26px] xl:text-[35px];
}

.description {
  @apply text-[14px];
}
</style>
