<template>
  <div id="focus" class="sm:px-[15px] md:container md:mx-auto lg:px-[70px] xl:container xl:mx-auto xl:px-[170px] py-10 smooth-scroll px-[20px] cursor-default font-barlow">
    <div class="flex flex-col text-center gap-y-5">
      <h2 class="heading font-extrabold">{{ $t('backupRecoveryPage.advanatage') }}</h2>
      <p class="description">
        {{ $t('backupRecoveryPage.design') }}
      </p>
    </div>

    <div class="py-10">
      <div
        class="grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-3 lg:grid-rows-1 lg:grid-cols-3 gap-10"
      >
        <div v-for="item in data" :key="item.id" class="text-center shadow-2xl p-7 border border-[#8e8f9a] rounded-md">
          <img :src="getImage(item.imageLink)" alt="" class="" />
          <div class="flex flex-col gap-y-3">
            <h2 class="title font-bold break-words">
              {{ item.title }}
            </h2>
            <p class="description text-gray-400">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).backupRecoveryPage.focusList;
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  }
}
</script>

<style scoped lang="postcss">
.heading {
  @apply text-[20px] sm:text-[32px] md:text-[36px] xl:text-[45px];
}

.title {
  @apply text-[20px] sm:text-[14px] md:text-[26px] xl:text-[35px];
}

.description {
  @apply text-gray-600 text-[16px] sm:text-[16px] md:text-[14px] xl:text-[16px];
}
</style>
