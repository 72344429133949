<template>
  <div class="blog py-10 font-barlow">
    <div class="py-10">
      <div class="blog-cards">
        <router-link
          v-for="item in data"
          :key="item.id"
          :to="`/blog-post/${item.blogRoute}`"
          class="relative shadow-xl bg-white transform-gpu transition-transform duration-300 perspective block"
          ref="cards"
          @mousemove="handleMouseMove"
          @mouseleave="handleMouseLeave"
          :data-card-id="item.id"
        >
          <div
            class="absolute top-9 left-7 h-[3px] w-[20px] bg-accent hover:bg-black transition-all duration-300 rounded"
          ></div>
          <img
            :src="getImage(item.blogImage)"
            class="h-56 object-top object-cover"
            alt=""
          />

          <div class="flex flex-col gap-y-5 w-fit px-8 py-6 mb-14">
            <p class="blog-date font-normal text-gray-500 text-start">
              {{ item.date }}
            </p>

            <p class="blog-title font-black text-start hover:opacity-70 transition-all duration-300 break-words">
              {{ item.title }}
            </p>

            <p class="blog-description text-gray-600 text-start break-words">
              {{ item.brief }}
            </p>

            <p
              class="mt-3 absolute bottom-10 left-8 text-accent font-bold w-fit border-b-2 border-accent hover:text-accent hover:border-accent"
            >
              {{ $t('blogPage.toTheArticle') }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogCards',
  computed: {
    data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).blogPage.blogsList
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    },
    handleMouseMove(event) {
      const card = event.currentTarget
      const rect = card.getBoundingClientRect()

      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      const centerX = rect.width / 2
      const centerY = rect.height / 2

      const tiltX = ((y - centerY) / centerY) * 10
      const tiltY = ((centerX - x) / centerX) * 10

      card.style.transform = `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`
      card.style.boxShadow = `
        0 10px 20px rgba(0,0,0,0.1),
        ${tiltY * 0.5}px ${tiltX * -0.5}px 10px rgba(0,0,0,0.1)
      `
    },
    handleMouseLeave(event) {
      const card = event.currentTarget
      card.style.transform = 'perspective(1000px) rotateX(0) rotateY(0)'
      card.style.boxShadow = '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)'
      card.style.transition = 'transform 0.5s ease-out, box-shadow 0.5s ease-out'
    }
  }
}
</script>

<style scoped lang="postcss">
.blog {
  @apply p-7 xl:container mx-auto;
}

.blog-cards {
  @apply grid grid-rows-[15] grid-cols-1 md:grid-rows-8 md:grid-cols-2 lg:grid-rows-5 lg:grid-cols-3 gap-5;
}

.blog-date {
  @apply text-[17px] md:text-[17px] lg:text-[17px] xl:text-[17px];
}
.blog-title {
  @apply text-[24px] md:text-[24px] lg:text-[24px] xl:text-[25px];
}
.blog-description {
  @apply text-[14px] md:text-[14px] lg:text-[14px] xl:text-[14px];
}

.perspective {
  transform-style: preserve-3d;
  will-change: transform;
}
</style>
