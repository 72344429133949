import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'
import HomePage from '@/views/HomePage.vue'
import ContactUs from '@/views/ContactUs.vue'
import AboutUs from '@/views/AboutUs.vue'
import ServicesPage from '@/views/ServicesPage.vue'
import BlogPage from '@/views/BlogPage.vue'
import BlogPost from '@/views/BlogPost.vue'
import DataProtection from  '@/views/DataProtection.vue'
import ImprintPage from  '@/views/ImprintPage.vue'

const baseRoutes = [
  { path: '', component: HomePage },
  { path: 'contact-us', component: ContactUs },
  { path: 'about-us', component: AboutUs },
  {
    path: 'services',
    children: [
      {
        path: '',
        component: ServicesPage
      },
      {
        path: ':name',
        component: () => import('@/views/ServicePage.vue'),
        props: true,
        ssr: true
      }
    ]
  },
  { path: 'blog', component: BlogPage },
  {
    path: 'blog-post',
    children: [
      {
        path: ':name',
        component: () => import('@/views/ServicePage.vue'),
        props: true
      }
    ],
    component: BlogPost,
    props: true
  },
  {
    path: 'backup-recovery',
    redirect: () => {
      return { path: '/services/backup-recovery' }
    }
  },
  { path: 'dataprotection', component: DataProtection },
  { path: 'imprint', component: ImprintPage },
];

const languages = ['EN', 'DE'];

const localizedRoutes = languages.flatMap((lang) =>
  baseRoutes.map(route => {
    if (route.children) {
      return {
        ...route,
        path: `/${lang.toLowerCase()}/${route.path}`,
        children: route.children.map(child => ({
          ...child,
          path: child.path,
          meta: { locale: lang }
        })),
        meta: { locale: lang }
      };
    }
    return {
      ...route,
      path: `/${lang.toLowerCase()}/${route.path}`,
      meta: { locale: lang }
    };
  })
);

const redirectRoutes = baseRoutes
  .filter(route => route.path !== '')
  .map(route => ({
    path: `/${route.path}`,
    // eslint-disable-next-line no-unused-vars
    redirect: to => {
      const savedLocale = localStorage.getItem('locale') || 'DE';
      return `/${savedLocale.toLowerCase()}/${route.path}`;
    }
  }));


const routes = [
  {
    path: '/',
    redirect: () => {
      const savedLocale = localStorage.getItem('locale') || 'DE';
      return `/${savedLocale.toLowerCase()}`;
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: to => {
      const savedLocale = localStorage.getItem('locale') || 'DE';
      return `/${savedLocale.toLowerCase()}${to.path}`;
    }
  },
  ...localizedRoutes,
  ...redirectRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  }
});

let isLocaleTransition = false;

router.beforeEach((to, from, next) => {
  const locale = to.path.split('/')[1];

  if (languages.map(lang => lang.toLowerCase()).includes(locale)) {
    const newLocale = locale.toUpperCase();

    if (!isLocaleTransition && i18n.global.locale !== newLocale) {
      isLocaleTransition = true;
      i18n.global.locale = newLocale;
      localStorage.setItem('locale', newLocale);
      isLocaleTransition = false;
    }
    next();
  } else {
    next();
  }
});

export default router
