<template>
  <div class="py-2 md:hidden centered-element-2 font-barlow">
    <div class="container mx-auto">
      <div class="flex flex-col gap-y-5">
        <router-link
          v-for="item in section2Data"
          :key="item.id"
          :to="`/services/${item.serviceRoute}`"
        >
          <div class="text-center flex flex-col items-center">
            <img
              :src="getImage(item.imageLink)"
              alt="item.name"
              class="h-auto max-w-[68%]"
            />
            <h3 class="font-bold text-xl text-gray-500">{{ item.name }}</h3>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section2Component',
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  },
  computed: {
    section2Data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).homepage.imageInfo
    }
  }
}
</script>

<style scoped>
.centered-element-2 {
  /* bottom: -230px; */
  /* position: absolute; */
  /* left: 50%; */
  transform: translateY(-15%);
}
</style>
