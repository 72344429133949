<template>
  <div class="mx-auto pb-8 font-barlow">
    <div
      class="banner flex uppercase justify-center items-center h-96 main text-4xl lg:text-5xl font-black pt-16 lg:pt-20 bg-"
    >
      <h1 class="max-w-6xl mx-auto px-4 my-8 text-2xl font-bold mb-6">
        Datenschutz
      </h1>
    </div>

    <div class="max-w-6xl mx-auto px-4 my-8">
      <p class="my-4">
        Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des
        Datenschutzrechts (insb. gemäß BDSG n.F. und der europäischen
        Datenschutz-Grundverordnung ‚DS-GVO') über die Art, den Umfang und Zweck
        der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese
        Datenschutzerklärung gilt auch für unsere Websites und
        Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa
        „personenbezogene Daten" oder „Verarbeitung" verweisen wir auf Art. 4
        DS-GVO.
      </p>
    </div>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Name und Kontaktdaten des / der Verantwortlichen
      </h2>
      <p class="mb-4">
        Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher") i.S.d. Art. 4
        Zif. 7 DS-GVO ist:
      </p>
      <address class="not-italic mb-4">
        WizardTales GmbH<br />
        Kokkolastr. 5<br />
        40882, Ratingen, Deutschland<br />
        Geschäftsführer: Tobias Gurtzick<br />
        Handelsregister/Nr.: HRB 84140<br />
        Registergericht: Amtsgericht Düsseldorf<br />
        E-Mail-Adresse: admin@wizardtales.com
      </address>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen
      </h2>
      <p class="mb-4">
        Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung,
        Verarbeitung und Nutzung personenbezogener Daten.
      </p>

      <h3 class="text-lg font-medium mb-2">
        1. Arten der Daten, die wir verarbeiten
      </h3>
      <p class="mb-4">
        Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.), Bestandsdaten
        (Name, Adresse etc.), Kontaktdaten (Telefonnummer, E-Mail, Fax etc.),
        Zahlungsdaten (Bankdaten, Kontodaten, Zahlungshistorie etc.),
        Vertragsdaten (Gegenstand des Vertrages, Laufzeit etc.),
        Kommunikationsdaten (IP-Adresse etc.),
      </p>

      <h3 class="text-lg font-medium mb-2">
        2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO
      </h3>
      <p class="mb-4">
        Abwicklung von Verträgen, Beweiszwecke / Beweissicherung, Website
        technisch und wirtschaftlich optimieren, Leichten Zugang zur Website
        ermöglichen, Erfüllung vertraglicher Verpflichtungen, Kontaktaufnahme
        bei juristischer Beanstandung durch Dritte, Erfüllung gesetzlicher
        Aufbewahrungspflichten, Optimierung und statistische Auswertung unserer
        Dienste, Kommerzielle Nutzung der Website unterstützen, Nutzererfahrung
        verbessern, Website benutzerfreundlich gestalten, Marketing / Vertrieb /
        Werbung, Erstellung von Statistiken, Abwicklung eines
        Bewerberverfahrens, Kundenservice und Kundenpflege, Kontaktanfragen
        abwickeln, Websites mit Funktionen und Inhalten bereitstellen, Maßnahmen
        der Sicherheit, Unterbrechungsfreier,sicherer Betrieb unserer Website,
      </p>

      <h3 class="text-lg font-medium mb-2">
        3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO
      </h3>
      <p class="mb-4">
        Besucher/Nutzer der Website, Kunden, Lieferanten, Interessenten,
        Bewerber, Beschäftigte,
      </p>

      <p class="mb-4">
        Die betroffenen Personen werden zusammenfassend als „Nutzer" bezeichnet.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Rechtsgrundlagen der Verarbeitung personenbezogener Daten
      </h2>
      <p class="mb-4">
        Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der
        Verarbeitung personenbezogener Daten:
      </p>
      <ul class="list-disc pl-5 mb-4 space-y-2">
        <li>
          Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen
          Daten eingeholt haben, ist Art. 6 Abs. 1 S. 1 lit. a) DS-GVO
          Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur
          Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Ihre
          Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO
          Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
          erforderlich, der wir unterliegen (z.B. gesetzliche
          Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO
          Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der
          betroffenen Person oder einer anderen natürlichen Person zu schützen,
          so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung zur Wahrung unserer oder der berechtigten
          Interessen eines Dritten erforderlich und überwiegen diesbezüglich
          Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist
          Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
        </li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter
      </h2>
      <p class="mb-4">
        Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte
        weiter. Sollte dies doch der Fall sein, dann erfolgt die Weitergabe auf
        der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der
        Weitergabe von Daten an Online-Paymentanbieter zur Vertragserfüllung
        oder aufgrund gerichtlicher Anordnung oder wegen einer gesetzlichen
        Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung,
        zur Gefahrenabwehr oder zur Durchsetzung der Rechte am geistigen
        Eigentum.
      </p>
      <p class="mb-4">
        Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum
        Webhosting unserer Websites und Datenbanken) zur Verarbeitung Ihrer
        Daten ein. Wenn im Rahmen einer Vereinbarung zur Auftragsverarbeitung an
        die Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer
        nach Art. 28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter
        sorgfältig aus, kontrollieren diese regelmäßig und haben uns ein
        Weisungsrecht hinsichtlich der Daten einräumen lassen. Zudem müssen die
        Auftragsverarbeiter geeignete technische und organisatorische Maßnahmen
        getroffen haben und die Datenschutzvorschriften gem. BDSG n.F. und
        DS-GVO einhalten
      </p>
    </section>

    <!-- Additional sections would continue here... -->

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Datenübermittlung in Drittstaaten
      </h2>
      <p class="mb-4">
        Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung
        (DS-GVO) wurde eine einheitliche Grundlage für den Datenschutz in Europa
        geschaffen. Ihre Daten werden daher vorwiegend durch Unternehmen
        verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die
        Verarbeitung durch Dienste Dritter außerhalb der Europäischen Union oder
        des Europäischen Wirtschaftsraums stattfinden, so müssen diese die
        besonderen Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das
        bedeutet, die Verarbeitung erfolgt aufgrund besonderer Garantien, wie
        etwa die von der EU-Kommission offiziell anerkannte Feststellung eines
        der EU entsprechenden Datenschutzniveaus oder der Beachtung offiziell
        anerkannter spezieller vertraglicher Verpflichtungen, der so genannten
        „Standardvertragsklauseln". Bei US-Unternehmen erfüllt die Unterwerfung
        unter das sog. „Privacy-Shield", dem Datenschutzabkommen zwischen der EU
        und den USA, diese Voraussetzungen.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Löschung von Daten und Speicherdauer
      </h2>
      <p class="mb-4">
        Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben,
        werden Ihre personenbezogen Daten gelöscht oder gesperrt, sobald die zur
        Verarbeitung erteilte Einwilligung von Ihnen widerrufen wird oder der
        Zweck für die Speicherung entfällt bzw. die Daten für den Zweck nicht
        mehr erforderlich sind, es sei denn deren weitere Aufbewahrung ist zu
        Beweiszwecken erforderlich oder dem stehen gesetzliche
        Aufbewahrungspflichten entgegenstehen. Darunter fallen etwa
        handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257
        Abs. 1 HGB (6 Jahre) sowie steuerrechtliche Aufbewahrungspflichten nach
        § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn die vorgeschriebene
        Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer
        Daten, es sei denn die Speicherung ist weiterhin für einen
        Vertragsabschluss oder zur Vertragserfüllung erforderlich.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Bestehen einer automatisierten Entscheidungsfindung
      </h2>
      <p class="mb-4">
        Wir setzen keine automatische Entscheidungsfindung oder ein Profiling
        ein.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Bereitstellung unserer Website und Erstellung von Logfiles
      </h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li class="mb-4">
          Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine
          Registrierung und auch keine anderweitige Übermittlung von
          Informationen), erheben wir nur die personenbezogenen Daten, die Ihr
          Browser an unseren Server übermittelt. Wenn Sie unsere Website
          betrachten möchten, erheben wir die folgenden Daten:
        </li>

        <ul class="list-disc pl-6 mb-4 space-y-1">
          <li>IP-Adresse;</li>
          <li>Internet-Service-Provider des Nutzers;</li>
          <li>Datum und Uhrzeit des Abrufs;</li>
          <li>Browsertyp;</li>
          <li>Sprache und Browser-Version;</li>
          <li>Inhalt des Abrufs;</li>
          <li>Zeitzone;</li>
          <li>Zugriffsstatus/HTTP-Statuscode;</li>
          <li>Datenmenge;</li>
          <li>Websites, von denen die Anforderung kommt;</li>
          <li>Betriebssystem.</li>
        </ul>

        <li class="mb-4">
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen
          Daten von Ihnen findet nicht statt.
        </li>

        <li class="mb-4">
          Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen
          und sicheren Auslieferung unserer Website an Sie mit Funktionen und
          Inhalten sowie deren Optimierung und statistischen Auswertung.
        </li>

        <li class="mb-4">
          Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes
          berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1
          lit. f) DS-GVO.
        </li>

        <li class="mb-4">
          Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles
          für die Speicherdauer von 70 Tagen. Nach Ablauf dieser Frist werden
          diese automatisch gelöscht, es sei denn wir benötigen deren
          Aufbewahrung zu Beweiszwecken bei Angriffen auf die
          Serverinfrastruktur oder anderen Rechtsverletzungen.
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Cookies</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p class="mb-4">
            Wir verwenden sog. Cookies bei Ihrem Besuch unserer Website. Cookies
            sind kleine Textdateien, die Ihr Internet-Browser auf Ihrem Rechner
            ablegt und speichert. Wenn Sie unsere Website erneut aufrufen, geben
            diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen.
            Zu den Cookies zählen auch die sog. „Nutzer-IDs", wo Angaben der
            Nutzer mittels pseudonymisierter Profile gespeichert werden. Wir
            informieren Sie dazu beim Aufruf unserer Website mittels eines
            Hinweises auf unsere Datenschutzerklärung über die Verwendung von
            Cookies zu den zuvor genannten Zwecken und wie Sie dieser
            widersprechen bzw. deren Speicherung verhindern können („Opt-out").
          </p>

          <p class="mb-4">Es werden folgende Cookie-Arten unterschieden:</p>

          <ul class="mb-4 space-y-4">
            <li>
              <strong>• Notwendige, essentielle Cookies:</strong> Essentielle
              Cookies sind Cookies, die zum Betrieb der Webseite unbedingt
              erforderlich sind, um bestimmte Funktionen der Webseite wie
              Logins, Warenkorb oder Nutzereingaben z.B. bzgl. Sprache der
              Webseite zu speichern.
            </li>

            <li>
              <strong>• Session-Cookies:</strong> Session-Cookies werden zum
              Wiedererkennen mehrfacher Nutzung eines Angebots durch denselben
              Nutzer (z.B. wenn Sie sich eingeloggt haben zur Feststellung Ihres
              Login-Status) benötigt. Wenn Sie unsere Seite erneut aufrufen,
              geben diese Cookies Informationen ab, um Sie automatisch
              wiederzuerkennen. Die so erlangten Informationen dienen dazu,
              unsere Angebote zu optimieren und Ihnen einen leichteren Zugang
              auf unsere Seite zu ermöglichen. Wenn Sie den Browser schließen
              oder Sie sich ausloggen, werden die Session-Cookies gelöscht.
            </li>

            <li>
              <strong>• Persistente Cookies:</strong> Diese Cookies bleiben auch
              nach dem Schließen des Browsers gespeichert. Sie dienen zur
              Speicherung des Logins, der Reichweitenmessung und zu
              Marketingzwecken. Diese werden automatisiert nach einer
              vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden
              kann. In den Sicherheitseinstellungen Ihres Browsers können Sie
              die Cookies jederzeit löschen.
            </li>

            <li>
              <strong
                >• Cookies von Drittanbietern (Third-Party-Cookies insb. von
                Werbetreibenden):</strong
              >
              Entsprechend Ihren Wünschen können Sie können Ihre
              Browser-Einstellung konfigurieren und z. B. Die Annahme von
              Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie
              jedoch an dieser Stelle darauf hin, dass Sie dann eventuell nicht
              alle Funktionen dieser Website nutzen können. Lesen Sie Näheres zu
              diesen Cookies bei den jeweiligen Datenschutzerklärungen zu den
              Drittanbietern.
            </li>
          </ul>
        </li>

        <li>
          <p>
            <strong>Datenkategorien:</strong> Nutzerdaten, Cookie, Nutzer-ID
            (inb. die besuchten Seiten, Geräteinformationen, Zugriffszeiten und
            IP-Adressen).
          </p>
        </li>

        <li>
          <p>
            <strong>Zwecke der Verarbeitung:</strong> Die so erlangten
            Informationen dienen dem Zweck, unsere Webangebote technisch und
            wirtschaftlich zu optimieren und Ihnen einen leichteren und sicheren
            Zugang auf unsere Website zu ermöglichen.
          </p>
        </li>

        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Wenn wir Ihre personenbezogenen
            Daten mit Hilfe von Cookies aufgrund Ihrer Einwilligung verarbeiten
            („Opt-in"), dann ist Art. 6 Abs. 1 S. 1 lit. a) DSGVO die
            Rechtsgrundlage. Ansonsten haben wir ein berechtigtes Interesse an
            der effektiven Funktionalität, Verbesserung und wirtschaftlichen
            Betrieb der Website, so dass in dem Falle Art. 6 Abs. 1 S. 1 lit. f)
            DS-GVO Rechtsgrundlage ist. Rechtsgrundlage ist zudem Art. 6 Abs. 1
            S. 1 lit. b) DS-GVO, wenn die Cookies zur Vertragsanbahnung z.B. bei
            Bestellungen gesetzt werden.
          </p>
        </li>

        <li>
          <p>
            <strong>Speicherdauer/ Löschung:</strong> Die Daten werden gelöscht,
            sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
            erforderlich sind. Im Falle der Erfassung der Daten zur
            Bereitstellung der Website ist dies der Fall, wenn die jeweilige
            Session beendet ist.
          </p>

          <p class="mb-4">
            Cookies werden ansonsten auf Ihrem Computer gespeichert und von
            diesem an unsere Seite übermittelt. Daher haben Sie als Nutzer auch
            die volle Kontrolle über die Verwendung von Cookies. Durch eine
            Änderung der Einstellungen in Ihrem Internetbrowser können Sie die
            Übertragung von Cookies deaktivieren oder einschränken. Bereits
            gespeicherte Cookies können jederzeit gelöscht werden. Dies kann
            auch automatisiert erfolgen. Werden Cookies für unsere Website
            deaktiviert, können möglicherweise nicht mehr alle Funktionen der
            Website vollumfänglich genutzt werden.
          </p>

          <p class="mb-4">
            Hier finden Sie Informationen zur Löschung von Cookies nach
            Browsern:
          </p>

          <p class="mb-1">
            <strong>Chrome:</strong>
            <a
              href="https://support.google.com/chrome/answer/95647"
              >https://support.google.com/chrome/answer/95647</a
            >
          </p>

          <p class="mb-1">
            <strong>Safari:</strong>
            <a
              href="https://support.apple.com/de-at/guide/safari/sfri11471/mac"
              >https://support.apple.com/de-at/guide/safari/sfri11471/mac</a
            >
          </p>

          <p class="mb-1">
            <strong>Firefox:</strong>
            <a
              href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen"
              >https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen</a
            >
          </p>

          <p class="mb-1">
            <strong>Internet Explorer:</strong>
            <a
              href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies"
              >https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies</a
            >
          </p>

          <p class="mb-4">
            <strong>Microsoft Edge:</strong>
            <a
              href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies"
              >https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies</a
            >
          </p>
        </li>

        <li>
          <p>
            <strong>Widerspruch und „Opt-Out":</strong> Das Speichern von
            Cookies auf Ihrer Festplatte können Sie unabhängig von einer
            Einwilligung oder gesetzlichen Erlaubnis allgemein verhindern, indem
            Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren"
            wählen. Dies kann aber eine Funktionseinschränkung unserer Angebote
            zur Folge haben. Sie können dem Einsatz von Cookies von
            Drittanbietern zu Werbezwecken über ein sog. „Opt-out" über
            <a
              href="https://optout.aboutads.info"
              >diese amerikanische Website</a
            >
            oder
            <a
              href="http://www.youronlinechoices.com/de/praferenzmanagement/"
              >diese europäische Website</a
            >
            widersprechen.
          </p>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Abwicklung von Verträgen</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li class="mb-4">
          Wir verarbeiten Bestandsdaten (z.B. Unternehmen, Titel/akademischer
          Grad, Namen und Adressen sowie Kontaktdaten von Nutzern, E-Mail),
          Vertragsdaten (z.B. in Anspruch genommene Leistungen, Namen von
          Kontaktpersonen) und Zahlungsdaten (z.B. Bankverbindung,
          Zahlungshistorie) zwecks Erfüllung unserer vertraglichen
          Verpflichtungen (Kenntnis, wer Vertragspartner ist; Begründung,
          inhaltliche Ausgestaltung und Abwicklung des Vertrags; Überprüfung auf
          Plausibilität der Daten) und Serviceleistungen (z.B. Kontaktaufnahme
          des Kundenservice) gem. Art. 6 Abs. 1 S. 1 lit b) DS-GVO. Die in
          Onlineformularen als verpflichtend gekennzeichneten Eingaben, sind für
          den Vertragsschluss erforderlich.
        </li>

        <li class="mb-4">
          Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht,
          außer sie ist zur Verfolgung unserer Ansprüche (z.B. Übergabe an
          Rechtsanwalt zum Inkasso) oder zur Erfüllung des Vertrags (z.B.
          Übergabe der Daten an Zahlungsanbieter) erforderlich oder es besteht
          hierzu besteht eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 S. 1
          lit. c) DS-GVO.
        </li>

        <li class="mb-4">
          Wir können die von Ihnen angegebenen Daten zudem verarbeiten, um Sie
          über weitere interessante Produkte aus unserem Portfolio zu
          informieren oder Ihnen E-Mails mit technischen Informationen zukommen
          lassen.
        </li>

        <li class="mb-4">
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Dies ist für die
          Bestands- und Vertragsdaten dann der Fall, wenn die Daten für die
          Durchführung des Vertrages nicht mehr erforderlich sind und keine
          Ansprüche mehr aus dem Vertrag geltend gemacht werden können, weil
          diese verjährt sind (Gewährleistung: zwei Jahre / Regelverjährung:
          drei Jahre). Wir sind aufgrund handels- und steuerrechtlicher Vorgaben
          verpflichtet, Ihre Adress-, Zahlungs- und Bestelldaten für die Dauer
          von zehn Jahren zu speichern. Allerdings nehmen wir bei
          Vertragsbeendigung nach drei Jahren eine Einschränkung der
          Verarbeitung vor, d. h. Ihre Daten werden nur zur Einhaltung der
          gesetzlichen Verpflichtungen eingesetzt. Angaben im Nutzerkonto
          verbleiben bis zu dessen Löschung.
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post
      </h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p>
            Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder
            E-Mail werden Ihre Angaben zum Zwecke der Abwicklung der
            Kontaktanfrage verarbeitet.
          </p>
        </li>

        <li>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
            einer Einwilligung von Ihnen Art. 6 Abs. 1 S. 1 lit. a) DS-GVO.
            Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
            Kontaktanfrage oder E-Mail, eines Briefes oder Faxes übermittelt
            werden, ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Der Verantwortliche
            hat ein berechtigtes Interesse an der Verarbeitung und Speicherung
            der Daten, um Anfragen der Nutzer beantworten zu können, zur
            Beweissicherung aus Haftungsgründen und um ggf. seiner gesetzlichen
            Aufbewahrungspflichten bei Geschäftsbriefen nachkommen zu können.
            Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist
            zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1
            lit. b) DS-GVO.
          </p>
        </li>

        <li>
          <p>
            Wir können Ihre Angaben und Kontaktanfrage in unserem
            Customer-Relationship-Management System ("CRM System") oder einem
            vergleichbaren System speichern.
          </p>
        </li>

        <li>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Für die
            personenbezogenen Daten aus der Eingabemaske des Kontaktformulars
            und diejenigen, die per E-Mail übersandt wurden, ist dies dann der
            Fall, wenn die jeweilige Konversation mit Ihnen beendet ist. Beendet
            ist die Konversation dann, wenn sich aus den Umständen entnehmen
            lässt, dass der betroffene Sachverhalt abschließend geklärt ist.
            Anfragen von Nutzern, die über einen Account bzw. Vertrag mit uns
            verfügen, speichern wir bis zum Ablauf von zwei Jahren nach
            Vertragsbeendigung. Im Fall von gesetzlichen Archivierungspflichten
            erfolgt die Löschung nach deren Ablauf: Ende handelsrechtlicher (6
            Jahre) und steuerrechtlicher (10 Jahre) Aufbewahrungspflicht.
          </p>
        </li>

        <li>
          <p>
            Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6
            Abs. 1 S. 1 lit. a) DS-GVO zur Verarbeitung der personenbezogenen
            Daten zu widerrufen. Nehmen Sie per E-Mail Kontakt mit uns auf, so
            können Sie der Speicherung der personenbezogenen Daten jederzeit
            widersprechen.
          </p>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Kontaktaufnahme per Telefon</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p>
            Bei der Kontaktaufnahme mit uns per Telefon wird Ihre Telefonnummer
            zur Bearbeitung der Kontaktanfrage und deren Abwicklung verarbeitet
            und temporär im RAM / Cache des Telefongerätes / Displays
            gespeichert bzw. angezeigt. Die Speicherung erfolgt aus Haftungs-
            und Sicherheitsgründen, um den Beweis des Anrufs führen zu können
            sowie aus wirtschaftlichen Gründen, um einen Rückruf zu ermöglichen.
            Im Falle von unberechtigten Werbeanrufen, sperren wir die
            Rufnummern.
          </p>
        </li>

        <li>
          <p>
            Rechtsgrundlage für die Verarbeitung der Telefonnummer ist Art. 6
            Abs. 1 S. 1 lit. f) DS-GVO. Zielt der Kontakt auf den Abschluss
            eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
            Verarbeitung Art. 6 Abs. 1 lit. b) DS-GVO.
          </p>
        </li>

        <li>
          <p>
            Der Gerätecache speichert die Anrufe 90 Tage und überschreibt bzw.
            löscht sukzessiv alte Daten, bei Entsorgung des Gerätes werden alle
            Daten gelöscht und der Speicher ggf. zerstört. Gesperrte
            Telefonnummer werden jährlich auf die Notwendigkeit der Sperrung
            geprüft.
          </p>
        </li>

        <li>
          <p>
            Sie können die Anzeige der Telefonnummer verhindern, indem Sie mit
            unterdrückter Telefonnummer anrufen.
          </p>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Google Analytics</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p>
            Wir haben das Webseitenanalyse-Tool „Google Analytics" (<strong
              >Dienstanbieter:</strong
            >
            Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow
            Street, Dublin 4, Irland) auf unserer Website integriert.
          </p>
        </li>

        <li>
          <p>
            <strong
              >Datenkategorien und Beschreibung der Datenverarbeitung:</strong
            >
            User-ID, IP-Adresse (anonymisiert). Beim Besuch unserer Website
            setzt Google einen Cookie auf Ihren Computer, um die Benutzung
            unserer Website durch Sie analysieren zu können. Wir haben die
            IP-Anonymisierung „anonymizeIP" aktiviert, wodurch die IP-Adressen
            nur gekürzt weiterverarbeitet werden. Auf dieser Webseite wird Ihre
            IP-Adresse von Google daher innerhalb von Mitgliedstaaten der
            Europäischen Union oder in anderen Vertragsstaaten des Abkommens
            über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
            Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
            in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
            dieser Webseite wird Google diese Informationen benutzen, um Ihre
            Nutzung der Webseite auszuwerten, um Reports über die
            Webseitenaktivitäten zusammenzustellen und um weitere, mit der
            Websitenutzung und der Internetnutzung verbundene, Dienstleistungen
            gegenüber dem Verantwortlichen zu erbringen. Wir haben darüber
            hinaus die geräteübergreifende Analyse von Website-Besuchern
            aktiviert, die über eine sog. User-ID durchgeführt wird. Die im
            Rahmen von Google Analytics von Ihrem Browser übermittelte
            IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            Weitere Informationen zu Datennutzung bei Google Analytics finden
            Sie hier:
            <a
              href="https://www.google.com/analytics/terms/de.html"
              >https://www.google.com/analytics/terms/de.html</a
            >
            (Nutzungsbedingungen von Analytics),
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=de"
              >https://support.google.com/analytics/answer/6004245?hl=de</a
            >
            (Hinweise zum Datenschutz bei Analytics) und Googles
            Datenschutzerklärung
            <a
              href="https://policies.google.com/privacy"
              >https://policies.google.com/privacy</a
            >.
          </p>
        </li>

        <li>
          <p>
            <strong>Zweck der Verarbeitung:</strong> Die Nutzung von Google
            Analytics dient dem Zweck der Analyse, Optimierung und Verbesserung
            unserer Website.
          </p>
        </li>

        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Haben Sie für Verarbeitung Ihrer
            personenbezogenen Daten mittels „Google Analytics" vom Drittanbieter
            Ihre Einwilligung erteilt („Opt-in"), dann ist Art. 6 Abs. 1 S. 1
            lit. a) DS-GVO die Rechtsgrundlage. Rechtsgrundlage ist zudem unser
            in den obigen Zwecken liegendes berechtigtes Interesse (der Analyse,
            Optimierung und Verbesserung unserer Website) an der
            Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO. Bei
            Services, die im Zusammenhang mit einem Vertrag erbracht werden,
            erfolgt das Tracking und die Analyse des Nutzerverhaltens nach Art.
            6 Abs. 1 S. 1 lit. b) DS-GVO, um mit den dadurch gewonnen
            Informationen, optimierte Services zur Erfüllung des Vertragszwecks
            anbieten zu können.
          </p>
        </li>

        <li>
          <p>
            <strong>Speicherdauer:</strong> Die von uns gesendeten und mit
            Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften
            Daten werden nach Monaten automatisch gelöscht. Die Löschung von
            Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch
            einmal im Monat.
          </p>
        </li>

        <li>
          <p>
            <strong>Datenübermittlung/Empfängerkategorie:</strong> Google,
            Irland und USA. Die gewonnenen Daten werden in die USA übertragen
            und dort gespeichert. Falls personenbezogen Daten in die USA
            übertragen werden sollten, bietet die Zertifizierung Googles gemäß
            Privacy-Shield-Abkommen (<a
              href="https://www.privacyshield.gov/EU-US-Framework"
              >https://www.privacyshield.gov/EU-US-Framework</a
            >) die Garantie dafür, dass das europäische Datenschutzrecht
            eingehalten wird. Wir haben zudem mit Google eine Vereinbarung zur
            Auftragsverarbeitung nach Art. 28 DS-GVO geschlossen.
          </p>
        </li>

        <li>
          <p>
            <strong
              >Widerspruchs- und Beseitigungsmöglichkeiten („Opt-Out"):</strong
            >
          </p>
          <ul class="pl-6 space-y-4">
            <li>
              <p>
                <strong>•Das Speichern von Cookies</strong> auf Ihrer Festplatte
                können Sie allgemein verhindern, indem Sie in Ihren
                Browser-Einstellungen „keine Cookies akzeptieren" wählen. Dies
                kann aber eine Funktionseinschränkung unserer Angebote zur Folge
                haben. Sie können darüber hinaus die Erfassung der, durch das
                Cookie erzeugten und auf Ihre Nutzung der Website bezogenen,
                Daten an Google sowie die Verarbeitung dieser Daten durch Google
                verhindern, indem sie das unter dem folgenden Link verfügbare
                Browser-Plugin herunterladen und installieren:
                <a
                  href="http://tools.google.com/dlpage/gaoptout?hl=de"
                  >http://tools.google.com/dlpage/gaoptout?hl=de</a
                >
              </p>
            </li>

            <li>
              <p>
                <strong>•Als Alternative zum obigen Browser-Plugin</strong>
                können Sie die Erfassung durch Google Analytics unterbinden,
                indem Sie [<em
                  >hier bitte den Analytics Opt-Out Link Ihrer Webseite
                  einfügen</em
                >] klicken. Durch den Klick wird ein „Opt-out"-Cookie gesetzt,
                das die Erfassung Ihrer Daten beim Besuch dieser Webseite
                zukünftig verhindert. Dieses Cookie gilt nur für unsere Webseite
                und Ihren aktuellen Browser und hat nur solange Bestand bis Sie
                Ihre Cookies löschen. In dem Falle müssten Sie das Cookie erneut
                setzen.
              </p>
            </li>

            <li>
              <p>
                <strong>•Die geräteübergreifende Nutzeranalyse</strong> können
                Sie in Ihrem Google-Account unter „Meine Daten > persönliche
                Daten" deaktivieren.
              </p>
            </li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Google ReCAPTCHA</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p>
            Wir haben auf unserer Webseite die Anti-Spam-Funktion „reCAPTCHA"
            von „Google" (<strong>Anbieter:</strong> Google Ireland Limited,
            Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland)
            integriert.
          </p>
        </li>

        <li>
          <p>
            <strong
              >Datenkategorie und Beschreibung der Datenverarbeitung:</strong
            >
            Nutzungsdaten (z.B. aufgerufene Webseite, IP). Durch den Einsatz von
            „reCAPTCHA" in unseren Formularen können wir feststellen, ob die
            Eingabe durch eine Maschine (Robot) oder einen Menschen erfolgte.
            Bei der Nutzung des Dienstes können Ihre IP-Adresse und ggf. weitere
            dafür benötigte Daten an Google-Server in den USA übertragen werden.
          </p>
        </li>

        <li>
          <p>
            <strong>Zweck der Verarbeitung:</strong> Vermeidung von Spam und
            Missbrauch sowie unser wirtschaftliches Interesse an der Optimierung
            unserer Website.
          </p>
        </li>

        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Haben Sie für Verarbeitung Ihrer
            personenbezogenen Daten mittels „reCaptcha" vom Drittanbieter Ihre
            Einwilligung erteilt („Opt-in"), dann ist Art. 6 Abs. 1 S. 1 lit. a)
            DS-GVO die Rechtsgrundlage. Rechtsgrundlage ist zudem unser in den
            obigen Zwecken liegendes berechtigtes Interesse an der
            Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
          </p>
        </li>

        <li>
          <p>
            <strong>Datenübermittlung/Empfängerkategorie:</strong> Drittanbieter
            in den USA.
          </p>
        </li>

        <li>
          <p>
            <strong>Speicherdauer:</strong> bis zur Löschung der Cookies durch
            Sie als Nutzer.
          </p>
        </li>

        <li>
          <p>
            Weitere Informationen finden Sie zu Google ReCAPTCHA unter
            <a
              href="https://www.google.com/recaptcha/"
              >https://www.google.com/recaptcha/</a
            >
            sowie in der Datenschutzerklärung von Google unter:
            <a
              href="https://policies.google.com/privacy"
              >https://policies.google.com/privacy</a
            >.
          </p>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Präsenz in sozialen Medien</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p>
            Wir unterhalten in sozialen Medien Profile bzw. Fanpages. Bei der
            Nutzung und dem Aufruf unseres Profils im jeweiligen Netzwerk durch
            Sie gelten die jeweiligen Datenschutzhinweise und
            Nutzungsbedingungen des jeweiligen Netzwerks.
          </p>
        </li>

        <li>
          <p>
            <strong
              >Datenkategorien und Beschreibung der Datenverarbeitung:</strong
            >
            Nutzungsdaten, Kontaktdaten, Inhaltsdaten, Bestandsdaten. Ferner
            werden die Daten der Nutzer innerhalb sozialer Netzwerke im
            Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So
            können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
            Interessen der Nutzer Nutzungsprofile erstellt werden. Die
            Nutzungsprofile können wiederum verwendet werden, um z.B.
            Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
            mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
            werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
            in denen das Nutzungsverhalten und die Interessen der Nutzer
            gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
            unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
            (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
            sind und bei diesen eingeloggt sind). Für eine detaillierte
            Darstellung der jeweiligen Verarbeitungsformen und der
            Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
            Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
            Netzwerke. Auch im Fall von Auskunftsanfragen und der Geltendmachung
            von Betroffenenrechten weisen wir darauf hin, dass diese am
            effektivsten bei den Anbietern geltend gemacht werden können. Nur
            die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und
            können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben.
            Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns
            wenden.
          </p>
        </li>

        <li>
          <p>
            <strong>Zweck der Verarbeitung:</strong> Kommunikation mit den auf
            den sozialen Netzwerken angeschlossenen und registrierten Nutzern;
            Information und Werbung für unsere Produkte, Angebote und
            Dienstleistungen; Außerdarstellung und Imagepflege; Auswertung und
            Analyse der Nutzer und Inhalte unserer Präsenzen in den sozialen
            Medien.
          </p>
        </li>

        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Die Rechtsgrundlage für die
            Verarbeitung der personenbezogenen Daten ist unser in den obigen
            Zwecken liegendes berechtigtes Interesse gemäß Art. 6 Abs. 1 S. 1
            lit. f) DS-GVO. Soweit Sie uns bzw. dem Verantwortlichen des
            sozialen Netzwerks eine Einwilligung in die Verarbeitung Ihrer
            personenbezogenen Daten erteilt haben, ist Rechtsgrundlage Art. 6
            Abs. 1 S. 1 lit. a) i.V.m. Art. 7 DS-GVO.
          </p>
        </li>

        <li>
          <p>
            <strong>Datenübermittlung/Empfängerkategorie:</strong> Soziales
            Netzwerk. Soweit die US-Anbieter unter dem Privacy-Shield-Abkommen
            zertifiziert (<a
              href="https://www.privacyshield.gov/EU-US-Framework"
              >https://www.privacyshield.gov/EU-US-Framework</a
            >) sind, wird sichergestellt, dass das europäische Datenschutzrecht
            eingehalten wird.
          </p>
        </li>

        <li>
          <p>
            Die Datenschutzhinweise, Auskunftsmöglichkeiten und
            Widerspruchmöglichkeiten (Opt-Out) der jeweiligen Netzwerke /
            Diensteanbieter finden Sie hier:
          </p>

          <ul class="pl-6 space-y-4 mt-2">
            <li>
              <p>
                <strong>• Twitter</strong> – Diensteanbieter: Twitter Inc., 1355
                Market Street, Suite 900, San Francisco, CA 94103, USA) -
                Datenschutzerklärung:
                <a
                  href="https://twitter.com/de/privacy"
                  >https://twitter.com/de/privacy</a
                >, Opt-Out:
                <a
                  href="https://twitter.com/personalization"
                  >https://twitter.com/personalization</a
                >, Privacy Shield:
                <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active"
                  >https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active</a
                >.
              </p>
            </li>

            <li>
              <p>
                <strong>• XING</strong> – Diensteanbieter: XING AG,
                Dammtorstraße 29-32, 20354 Hamburg, Deutschland) -
                Datenschutzerklärung/ Opt-Out:
                <a
                  href="https://privacy.xing.com/de/datenschutzerklaerung"
                  >https://privacy.xing.com/de/datenschutzerklaerung</a
                >.
              </p>
            </li>

            <li>
              <p>
                <strong>• LinkedIn</strong> – Diensteanbieter: LinkedIn Ireland
                Unlimited Company, Wilton Place, Dublin 2, Irland) –
                Datenschutzerklärung:
                <a
                  href="https://www.linkedin.com/legal/privacy-policy"
                  >https://www.linkedin.com/legal/privacy-policy</a
                >, Cookie-Richtlinie und Opt-Out:
                <a
                  href="https://www.linkedin.com/legal/cookie-policy"
                  >https://www.linkedin.com/legal/cookie-policy</a
                >, Privacy Shield der US-Firma LinkedIn Inc.:
                <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active"
                  >https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active</a
                >.
              </p>
            </li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Datenschutz bei Bewerbungen und im Bewerberverfahren
      </h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p>
            Bewerbungen, die auf elektronischem Wege oder per Post an den
            Verantwortlichen gesendet werden, werden zum Zwecke der Abwicklung
            des Bewerberverfahrens elektronisch oder manuell verarbeitet.
          </p>
        </li>

        <li>
          <p>
            Wir weisen ausdrücklich darauf hin, dass Bewerbungsunterlagen mit
            „besonderen Kategorien personenbezogener Daten" nach Art. 9 DS-GVO
            (z.B. ein Foto, welches Rückschlüsse auf Ihre ethnische Herkunft,
            Religion oder Ihren Familienstand gibt), mit Ausnahme einer
            eventuellen Schwerbehinderung, welche Sie aus freier Entscheidung
            offenlegen möchten, unerwünscht sind. Sie sollen Ihre Bewerbung ohne
            diese Daten einreichen. Dies hat keine Auswirkungen auf Ihre
            Bewerberchancen.
          </p>
        </li>

        <li>
          <p>
            Rechtsgrundlagen für die Verarbeitung sind Art. 6 Abs. 1 S.1 lit. b)
            DS-GVO sowie § 26 BDSG n.F.
          </p>
        </li>

        <li>
          <p>
            Wird nach Abschluss des Bewerberverfahrens, ein Arbeitsverhältnis
            mit dem Bewerber / der Bewerberin eingegangen, werden die
            Bewerberdaten unter Beachtung einschlägiger Datenschutzvorschriften
            gespeichert. Wird Ihnen nach Abschluss des Bewerberverfahrens keine
            Stelle angeboten, so wird Ihr eingereichtes Bewerbungsschreiben samt
            Unterlagen 6 Monate nach Versand der Absage gelöscht, um etwaigen
            Ansprüchen und Nachweispflichten nach AGG genügen zu können.
          </p>
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">Rechte der betroffenen Person</h2>

      <ol class="list-decimal pl-6 mb-4 space-y-4">
        <li>
          <p class="font-semibold">
            Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten
          </p>

          <p class="mb-4">
            Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1
            S. 1 lit. a), Art. 7 DS-GVO beruht, haben Sie das Recht, die
            Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            wird dadurch nicht berührt.
          </p>

          <p class="mb-4">
            Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
            Interessenabwägung gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO stützen,
            können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der
            Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines
            Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der
            nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei
            Ausübung eines solchen Widerspruchs bitten wir um Darlegung der
            Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns
            durchgeführt verarbeiten sollten. Im Falle Ihres begründeten
            Widerspruchs prüfen wir die Sachlage und werden entweder die
            Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere
            zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die
            Verarbeitung fortführen.
          </p>

          <p class="mb-4">
            Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke
            der Werbung und Datenanalyse jederzeit widersprechen. Das
            Widerspruchsrecht können Sie kostenfrei ausüben. Über Ihren
            Werbewiderspruch können Sie uns unter folgenden Kontaktdaten
            informieren:
          </p>

          <address class="not-italic mb-4 pl-4 border-l-4 border-gray-300">
            WizardTales GmbH<br />
            Kokkolastr. 5<br />
            40882, Ratingen, Deutschland<br />
            Geschäftsführer Tobias Gurtzick<br />
            Handelsregister/Nr.: HRB 84140<br />
            Registergericht: Amtsgericht Düsseldorf<br />
            E-Mail-Adresse: admin@wizardtales.com
          </address>
        </li>

        <li>
          <p class="font-semibold">Recht auf Auskunft</p>
          <p class="mb-4">
            Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen,
            ob Sie betreffende personenbezogene Daten verarbeitet werden. Sofern
            dies der Fall ist, haben Sie ein Recht auf Auskunft über Ihre bei
            uns gespeicherten persönlichen Daten nach Art. 15 DS-GVO. Dies
            beinhaltet insbesondere die Auskunft über die Verarbeitungszwecke,
            die Kategorie der personenbezogenen Daten, die Kategorien von
            Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder
            werden, die geplante Speicherdauer, die Herkunft Ihrer Daten, sofern
            diese nicht direkt bei Ihnen erhoben wurden.
          </p>
        </li>

        <li>
          <p class="font-semibold">Recht auf Berichtigung</p>
          <p class="mb-4">
            Sie haben ein Recht auf Berichtigung unrichtiger oder auf
            Vervollständigung richtiger Daten nach Art. 16 DS-GVO.
          </p>
        </li>

        <li>
          <p class="font-semibold">Recht auf Löschung</p>
          <p class="mb-4">
            Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten
            nach Art. 17 DS-GVO, es sei denn gesetzliche oder vertraglichen
            Aufbewahrungsfristen oder andere gesetzliche Pflichten bzw. Rechte
            zur weiteren Speicherung stehen dieser entgegen.
          </p>
        </li>

        <li>
          <p class="font-semibold">Recht auf Einschränkung</p>
          <p class="mb-4">
            Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen, wenn eine der Voraussetzungen
            in Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt ist:
          </p>
          <ul class="list-disc pl-6 mb-4 space-y-2">
            <li>
              Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
              für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht,
              die Richtigkeit der personenbezogenen Daten zu überprüfen;
            </li>
            <li>
              die Verarbeitung unrechtmäßig ist und Sie die Löschung der
              personenbezogenen Daten ablehnen und stattdessen die Einschränkung
              der Nutzung der personenbezogenen Daten verlangen;
            </li>
            <li>
              der Verantwortliche die personenbezogenen Daten für die Zwecke der
              Verarbeitung nicht länger benötigt, Sie diese jedoch zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              benötigen, oder
            </li>
            <li>
              wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
              DS-GVO eingelegt haben und noch nicht feststeht, ob die
              berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
              überwiegen.
            </li>
          </ul>
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DataProtection'
}
</script>

<style scoped>
.banner {
  background-image: url(@/assets/images/wx-one-home-head-cloud.jpg);
  padding-top: 40px;
  padding-right: 0px;
  padding-bottom: 30px;
  padding-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  background-position: 50% 48.5%;
  margin-top: 90px;
}

section {
  @apply max-w-6xl mx-auto px-4;
}

</style>
