<template>
  <div class="container mx-auto py-10 px-[30px] font-barlow">
    <div class="">
      <div class="flex gap-x-10 justify-center items-center">
        <!-- subtitles -->
        <div v-for="(item, index) in data" :key="index" class="w-fit" :hidden="item.hidden">
          <button
            v-if="item.title === 'About WX-ONE' || item.title === 'Über WX-ONE'"
            class="btn font-bold text-accent transition-all w-fit"
            :class="item.title === 'About WX-ONE' || item.title === 'Über WX-ONE' ? 'subtitle-btn active' : 'subtitle-btn'"
          >
            <a :href="item.link" class="">
              {{ item.title }}
            </a>
          </button>
          <button
            v-else
            class="btn font-bold hover:text-accent w-fit subtitle-btn"
          >
            <a :href="item.link">
              {{ item.title }}
            </a>
          </button>
        </div>
      </div>
    </div>

    <div
      id="aboutWx1"
      class="flex flex-col items-center justify-center gap-y-10 py-10 pt-[102px]"
    >
      <div class="text-center cursor-default">
        <h3 class="about-title font-extrabold">{{ $t('aboutus.aboutWX') }}</h3>
      </div>
      <div
        class="flex flex-col gap-y-1 text-justify about-description text-gray-500 cursor-default"
        v-html="sanitizedHtml"
      ></div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify'

export default {
  name: 'AboutUs',
  computed: {
    sanitizedHtml() {
      return DOMPurify.sanitize(this.description)
    },
    data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).aboutus.subtitles
    },
    description() {
      return this.$i18n.getLocaleMessage(
      this.$i18n.locale
    ).aboutus.description
    }
  }
}
</script>

<style scoped lang="postcss">
.subtitle-btn {
  position: relative;
  display: inline-block;
  color: inherit;
}

.subtitle-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: -20px;
  width: 100%;
  height: 4px;
  background-color: #54b1b9;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}
.subtitle-btn:hover {
  color: #54b1b9;
}
.subtitle-btn:hover::before {
  transform: scaleX(1);
}

.subtitle-btn.active {
  color: #54b1b9;
}

.subtitle-btn.active::before {
  transform: scaleX(1);
}

.btn {
  @apply text-[12px] sm:text-[12px] md:text-[17.5px];
}

.about-title {
  @apply text-[20px] sm:text-[32px] md:text-[36px] xl:text-[45px];
}

.about-description {
  @apply text-[15px] sm:text-[14px] md:text-[15px];
}
</style>
