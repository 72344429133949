<template>
  <!-- Form -->
  <div
    class="container mx-auto max-w-3xl font-barlow"
    id="form"
    v-if="!msgSent"
  >
    <div class="flex flex-col gap-y-5 py-10 px-12">
      <div class="text-center flex flex-col gap-y-10">
        <h2 class="text-3xl lg:text-4xl font-extrabold">
          {{ $t('contactPage.letUsHelp') }}
        </h2>
        <p class="text-sm text-gray-500 px-5">
          {{ $t('contactPage.brieflyDescribe') }}
        </p>
      </div>

      <div class="flex flex-col gap-3">
        <input
          class=""
          type="text"
          :placeholder="$t('contactPage.name')"
          v-model="formData.name"
        />
        <input
          type="email"
          :placeholder="$t('contactPage.email')"
          v-model="formData.email"
        />
        <input
          type="text"
          :placeholder="$t('contactPage.phone')"
          v-model="formData.phone"
        />
        <input
          type="text"
          :placeholder="$t('contactPage.company')"
          v-model="formData.company"
        />
      </div>
      <div>
        <textarea
          rows="5"
          :placeholder="$t('contactPage.message')"
          v-model="formData.textarea"
        ></textarea>
      </div>
      <div class="html-embed w-embed w-iframe">
        <div style="width: 304px; height: 78px">
          <iframe
            title="mCaptcha"
            src="https://captcha.wx1.de/widget/?sitekey=hjLCTt2bRbZrnYMZJrLtxVdaLvcrejNc"
            role="presentation"
            name="mcaptcha-widget__iframe"
            id="mcaptcha-widget__iframe"
            scrolling="no"
            sandbox="allow-same-origin allow-scripts"
            width="304"
            height="78"
            frameborder="0"
          ></iframe>
        </div>
      </div>
      <div class="w-full">
        <button
          @click="submitForm"
          class="bg-accent w-full px-5 py-3 text-lg text-white font-bold hover:bg-white border-2 border-accent hover:text-accent rounded-3xl transition-all duration-300"
        >
          {{ $t('contactPage.sendReq') }}
        </button>
      </div>
    </div>
  </div>
  <div class="text-center container mx-auto" v-else>
    <div class="flex flex-col gap-y-5 pt-10">
      <h1 class="text-5xl font-extrabold text-black">
        {{ $t('msgSentTitle') }}
      </h1>
      <p class="opacity-50">{{ $t('msgSentDescription1') }}</p>
      <div class="gap-y-3">
        <p>{{ $t('msgSentDescription2') }}</p>
        <p class="pt-0">{{ $t('msgSentDescription3') }}</p>
      </div>
      <p>{{ $t('msgSentDescription4') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        company: '',
        textarea: '',
        token: ''
      },
      msgSent: false
    }
  },
  mounted() {
    window.addEventListener('message', this.handleCaptchaResponse)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleCaptchaResponse)
  },
  methods: {
    handleCaptchaResponse(event) {
      if (event.origin === window.mcaptchaOrigin) {
        const token = event.data.token
        if (token) {
          this.formData.token = token
        }
      }
    },
    async submitForm() {
      if (this.formData.token === '') {
        alert('Please check the captcha first')
        return
      }
      const object = {
        Email: this.formData.email,
        Message: this.formData.textarea,
        Name: this.formData.name,
        Telefon: this.formData.phone,
        Unternehmen: this.formData.company,
        captcha: this.formData.token
      }

      if (
        object.Name === '' ||
        object.Email === '' ||
        object.Telefon === '' ||
        object.Unternehmen === '' ||
        object.Message === ''
      ) {
        alert('Please fill out all fields')
        return
      }

      try {
        const response = await axios.post(window.APIEmailEndpoint, object, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          withCredentials: false
        })
        if (response.status === 200) {
          this.msgSent = true
          this.formData = {
            name: '',
            email: '',
            phone: '',
            company: '',
            textarea: '',
            token: ''
          }
        }
      } catch (error) {
        return error
      }
    }
  }
}
</script>

<style scoped>
input,
textarea {
  padding: 11px;
  @apply border rounded;
}

input:focus-visible,
textarea:focus-visible {
  @apply outline outline-accent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
