<template>
  <div class="flex flex-col min-h-screen">
    <NavBar />
    <div class="flex-grow">
      <router-view :key="$route.fullPath" />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import FooterComponent from '@/components/FooterComponent.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'App',
  components: {
    FooterComponent,
    NavBar,
  },
  setup() {
    const { locale } = useI18n();
    const router = useRouter();

    watch(
      () => locale.value,
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) return;
        const currentRoute = router.currentRoute.value;
        const currentPath = currentRoute.path;
        const currentLocale = currentPath.split('/')[1];
        if (currentLocale !== newLocale.toLowerCase()) {
          let pathWithoutLocale = currentPath.replace(/^\/[a-z]{2}/, '');
          if (pathWithoutLocale.startsWith("/services/") && pathWithoutLocale.length > "/services/".length) {
            const newParam = sessionStorage.getItem('serviceOtherLanguage')
            pathWithoutLocale = pathWithoutLocale.replace(/\/services\/[^/]+/, `/services/${newParam}`);
          }
          if (pathWithoutLocale.startsWith("/blog-post/") && pathWithoutLocale.length > "/blog-post/".length) {
            const newParam = sessionStorage.getItem('blogOtherLanguage')
            pathWithoutLocale = pathWithoutLocale.replace(/\/blog-post\/[^/]+/, `/blog-post/${newParam}`);
          }
          
          const newPath = `/${newLocale.toLowerCase()}${pathWithoutLocale}`;
          if (newPath !== currentPath) {
            router.push(newPath).catch(() => {});
          }
        }
      },
      { immediate: false }
    );
  },
};
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
}

.no-scroll {
  overflow: hidden;
}

::selection {
    color: white;
    background-color: #152D3E;
}

img {
  user-select: none;
  user-drag: none;
}
</style>
