<template>
  <HeroHeader />
  <ScrollAnimation>
    <ServiceCards />
  </ScrollAnimation>
  <ScrollAnimation>
    <Section3Component />
  </ScrollAnimation>
</template>

<script>
import HeroHeader from '../components/Services/HeroHeader.vue'
import ServiceCards from '../components/Services/ServiceCards.vue'
import Section3Component from '../components/Services/Section3Component.vue'
import { nextTick } from 'vue'
import ScrollAnimation from '@/components/ScrollAnimation.vue'

export default {
  components: {
    HeroHeader,
    ServiceCards,
    Section3Component,
    ScrollAnimation
  },
  created() {
    document.title = 'Services — WX-ONE'
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0, 0)
    })
  }
}
</script>

<style scoped></style>
