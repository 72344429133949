<template>
  <div>
    <div class="text-start my-8">
      <h2 class="text-3xl max-w-6xl px-4 mx-auto font-medium text-gray-800 mb-4">
        {{ currentLanguage.title }}
      </h2>
    </div>
    <div class="max-w-6xl px-4 mx-auto mb-8">
      <p v-for="(paragraph, index) in currentLanguage.mainParagraphs" :key="index" class="text-gray-600 mb-4">
        {{ paragraph }}
      </p>
    </div>
    <div class="bg-primary mt-4">
      <div class="max-w-6xl p-4 text-base font-normal mx-auto rounded-lg flex flex-col md:flex-row justify-between items-center">
        <h3 class="text-white font-semibold mb-4 md:mb-0">
          {{ currentLanguage.ctaHeading }}
        </h3>
        <a :href="`/${$i18n.locale.toLowerCase()}/contact-us`" class="bg-accent w-max text-white text-center py-3 px-5 rounded-full">
          {{ currentLanguage.ctaButton }}
        </a>
      </div>
    </div>
    <div style="background-color: #dae2ea" class="py-16">
      <div class="max-w-6xl px-4 mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div v-for="(feature, index) in currentLanguage.features" :key="index" class="bg-white p-6 shadow-md">
          <div class="text-accent mb-4">
            <i :class="feature.icon" class="text-5xl" aria-hidden="true"></i>
          </div>
          <h4 class="text-accent text-xl font-semibold mb-4">
            {{ feature.title }}
          </h4>
          <p class="text-gray-700">
            {{ feature.description }}
          </p>
        </div>
        <div class="bg-white p-6 shadow-md flex items-center justify-center">
          <img src="/service/1.webp" alt="Cloud Services" class="max-w-full h-auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudSolutionsComponent',
  data() {
    return {
      languages: {
        DE: {
          title: 'Entfesseln Sie das volle Potenzial von Docker und Kubernetes mit unseren Cloud-Lösungen!',
          mainParagraphs: [
            'In der immer komplexer werdenden Welt der Technologie sind Docker und Kubernetes Ihre Verbündeten für eine reibungslose und effiziente Anwendungsbereitstellung. Docker ermöglicht es Ihnen, Anwendungen in Container zu verpacken, die alle notwendigen Teile wie Bibliotheken und Abhängigkeiten enthalten, um sicherzustellen, dass sie überall dort, wo sie eingesetzt werden, gleich funktionieren. Das Ergebnis? Sie reduzieren Komplexität und verbessern die Zuverlässigkeit.',
            'Auf der anderen Seite steht Kubernetes, ein Open-Source-System zur Automatisierung der Bereitstellung, Skalierung und Verwaltung von containerisierten Anwendungen. Mit Kubernetes können Sie Ihre Containerinfrastruktur verwalten und optimieren, um maximale Leistung und Effizienz zu erzielen.',
            'Jetzt fragen Sie sich vielleicht: "Wie passe ich in all dies ein?" Hier kommen wir ins Spiel. Mit unseren bewährten Cloud-Lösungen haben wir schon zahlreiche Erfolge im Bereich Docker und Kubernetes erzielt. Wir haben das Wissen, die Fähigkeiten und die Erfahrung, um Ihnen zu helfen, diese leistungsstarken Tools optimal zu nutzen.',
            'Von der Konfiguration und Verwaltung von Docker-Containern bis hin zur Skalierung Ihrer Anwendungen mit Kubernetes - wir sind Ihr vertrauenswürdiger Partner. Unser Expertenteam hat bereits großen Unternehmen dabei geholfen, den Übergang in die Cloud zu meistern, die Effizienz ihrer Systeme zu steigern und ihre Geschäftsziele zu erreichen.',
            'Mit uns an Ihrer Seite sind Docker und Kubernetes nicht mehr nur Werkzeuge, sie sind Ihre Geheimwaffe für Erfolg und Wachstum. Machen Sie den Sprung in die Cloud und entdecken Sie, was mit Docker, Kubernetes und unseren maßgeschneiderten Cloud-Lösungen möglich ist.'
          ],
          ctaHeading: 'Lassen Sie uns die digitale Transformation Ihres Unternehmens gemeinsam gestalten.',
          ctaButton: 'BERATUNGSTERMIN VEREINBAREN',
          features: [
            {
              icon: 'fab fa-cloudscale',
              title: 'Unbegrenzte Skalierbarkeit',
              description: 'Unsere Cloud-Lösungen passen sich an Ihre Bedürfnisse an, egal ob Sie gerade erst starten oder ein etabliertes Unternehmen weiter ausbauen möchten. Sie können Ressourcen hinzufügen oder reduzieren, um optimal auf Geschäftsschwankungen zu reagieren.'
            },
            {
              icon: 'fas fa-lock',
              title: 'Höchste Sicherheit',
              description: 'Wir legen größten Wert auf die Sicherheit Ihrer Daten. Unsere Cloud-Lösungen bieten modernste Sicherheitsmaßnahmen, um Ihre Daten vor potenziellen Bedrohungen zu schützen und den Datenschutz zu gewährleisten.'
            },
            {
              icon: 'fas fa-euro-sign',
              title: 'Kosteneffizienz',
              description: 'Statt in teure Hardware und Wartung zu investieren, zahlen Sie nur für die Ressourcen, die Sie tatsächlich nutzen. Dies reduziert Ihre IT-Kosten erheblich und ermöglicht es Ihnen, Ihr Budget effektiver zu nutzen.'
            },
            {
              icon: 'fa fa-bolt',
              title: 'Schnelle Implementierung & Innovation',
              description: 'Unsere Cloud-Lösungen ermöglichen es Ihnen, neue Anwendungen schnell zu implementieren und zu testen, was die Markteinführungszeit verkürzt. Zudem haben Sie Zugang zu den neuesten Technologien, um Innovationen voranzutreiben.'
            },
            {
              icon: 'fas fa-headset',
              title: '24/7 Zugang & Support',
              description: 'Sie können auf Ihre Daten und Anwendungen von überall und zu jeder Zeit zugreifen. Unser engagiertes Support-Team steht Ihnen rund um die Uhr zur Verfügung, um bei Fragen oder Problemen zu helfen.'
            }
          ]
        },
        EN: {
          title: 'Unleash the Full Potential of Docker and Kubernetes with Our Cloud Solutions!',
          mainParagraphs: [
            'In the increasingly complex world of technology, Docker and Kubernetes are your allies for smooth and efficient application deployment. Docker enables you to package applications in containers that include all necessary components like libraries and dependencies, ensuring they function consistently wherever they\'re deployed. The result? You reduce complexity and improve reliability.',
            'On the other hand, Kubernetes is an open-source system for automating deployment, scaling, and management of containerized applications. With Kubernetes, you can manage and optimize your container infrastructure to achieve maximum performance and efficiency.',
            'Now you might be wondering: "How do I fit into all this?" This is where we come in. With our proven cloud solutions, we\'ve already achieved numerous successes in Docker and Kubernetes. We have the knowledge, skills, and experience to help you make optimal use of these powerful tools.',
            'From configuring and managing Docker containers to scaling your applications with Kubernetes - we are your trusted partner. Our expert team has already helped large companies master the transition to the cloud, increase the efficiency of their systems, and achieve their business goals.',
            'With us by your side, Docker and Kubernetes are no longer just tools, they are your secret weapon for success and growth. Take the leap into the cloud and discover what\'s possible with Docker, Kubernetes, and our tailored cloud solutions.'
          ],
          ctaHeading: 'Let\'s shape the digital transformation of your company together.',
          ctaButton: 'SCHEDULE CONSULTATION',
          features: [
            {
              icon: 'fab fa-cloudscale',
              title: 'Unlimited Scalability',
              description: 'Our cloud solutions adapt to your needs, whether you\'re just starting out or looking to expand an established business. You can add or reduce resources to optimally respond to business fluctuations.'
            },
            {
              icon: 'fas fa-lock',
              title: 'Maximum Security',
              description: 'We place the highest value on the security of your data. Our cloud solutions offer state-of-the-art security measures to protect your data from potential threats and ensure data privacy.'
            },
            {
              icon: 'fas fa-euro-sign',
              title: 'Cost Efficiency',
              description: 'Instead of investing in expensive hardware and maintenance, you only pay for the resources you actually use. This significantly reduces your IT costs and allows you to use your budget more effectively.'
            },
            {
              icon: 'fa fa-bolt',
              title: 'Fast Implementation & Innovation',
              description: 'Our cloud solutions allow you to quickly implement and test new applications, reducing time to market. You also have access to the latest technologies to drive innovation.'
            },
            {
              icon: 'fas fa-headset',
              title: '24/7 Access & Support',
              description: 'You can access your data and applications from anywhere, anytime. Our dedicated support team is available around the clock to help with any questions or issues.'
            }
          ]
        }
      },
      currentLanguage: null
    }
  },
  created() {
    this.setLanguage(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      this.setLanguage(newLocale);
    }
  },
  methods: {
    setLanguage(locale) {
      this.currentLanguage = this.languages[locale] || this.languages.EN;
    }
  }
};
</script>
