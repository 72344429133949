<template>
  <div id="team" class="container mx-auto py-10 px-[30px] font-barlow cursor-default">
    <div class="flex flex-col text-center">
      <h2 class="main font-extrabold">{{ $t('aboutus.yourContactPerson') }}</h2>
    </div>

    <div class="grid grid-rows-10 grid-cols-1 md:grid-rows-4 md:grid-cols-3 lg:grid-rows-4 lg:grid-cols-3 xl:grid-rows-3 xl:grid-cols-4 gap-5 py-10">
      <div v-for="item in data" :key="item.id" class="shadow-lg rounded flex flex-col bg-gray-100 md:h-[350px] lg:h-[400px] xl:w-[300px] mx-auto">
        <img
          :src="getImage(item.imageLink)"
          alt=""
          class="rounded-tr rounded-tl md:h-[200px] lg:h-[290px] lg:w-[300px]"
        />
        <div class="flex flex-col gap-y-5 p-5">
          <h2 class="font-extrabold title">
            {{ item.title }}
          </h2>
          <p class="description text-gray-600">
            {{ item.position }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section5Component',
  computed: {
    data() {
      return  this.$i18n.getLocaleMessage(this.$i18n.locale).aboutus.list;
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  }
}
</script>

<style scoped lang="postcss">
.main {
  @apply text-[16px] sm:text-[22px] md:text-[36px] xl:text-[45px];
}

.title {
  @apply text-[20px] sm:text-[20px] md:text-[20px] xl:text-[20px];
}

.description {
  @apply text-[14px] sm:text-[14px] md:text-[14px] xl:text-[14px];
}
</style>
