<template>
  <div class="img relative mt-[10px] h-[750px] md:h-[970px] lg:h-[900px] xl:h-[1100px] font-barlow">
    <div class="overflow-x-hidden">
      <div class="content-height">
        <div class="content-position">
          <h1
            class="text-wrap font-[700] title w-[245px] md:w-[454px] lg:w-[600px] xl:w-[670px] leading-none"
          >
            {{ $t('homepage.banner.title') }}
          </h1>
          <div class="border-b-2 border-white w-[100px] py-5"></div>
          <h3
            class="text-wrap font-medium description md:w-[500px] lg:w-[424px] xl:w-[450px]"
          >
            {{ $t('homepage.banner.description') }}
          </h3>
          <RouterLink
            :to="'/contact-us'"
            class="w-[325px] h-[60px] sm:w-fit py-5 lg:py-5 xl:py-3"
          >
            <button class="contact-btn">
              {{ $t('homepage.banner.button') }}
            </button>
          </RouterLink>
        </div>
        <div
          class="hidden md:block center-elements position-element container mx-auto z-30 mb-14 transform transition-all duration-1000"
        >
          <div class="flex flex-col gap-y-5 md:flex-row">
            <router-link
                v-for="item in section2Data"
                :key="item.id"
                :to="`/services/${item.serviceRoute}`"
              >
              <div
                class="text-center flex flex-col items-center"
              >
                <ScrollAnimation>
                  <div class="flex flex-col justify-center items-center hover:text-accent">
                    <img
                      :src="getImage(item.imageLink)"
                      alt="item.name"
                      class="h-auto img-size"
                    />
                    <h3 class="font-bold text-xl">{{ item.name }}</h3>
                  </div>
                </ScrollAnimation>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollAnimation from '../ScrollAnimation.vue'
export default {
  name: 'HeroHeader',
  components: {
    ScrollAnimation
  },
  created() {
    document.title = 'WX-ONE - Die neue Cloud aus Deutschland'
  },
  data() {
    return {}
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  },
  computed: {
    section2Data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).homepage.imageInfo
    }
  }
}
</script>

<style scoped lang="postcss">
.hero-header {
  @apply xl:h-[1000px];
}

.title {
  @apply text-[35px] md:text-[65px] xl:text-[90px] text-white;
}

.description {
  @apply text-[18px] md:text-[22px] text-white pt-[15px];
}

.content-height {
  @apply ml-[20px] mr-[60px] md:my-[90px] md:py-[50px] xl:pl-[20px] w-fit;
}

.content-position {
  @apply flex flex-col sm:p-5 md:p-0 my-[110px] sm:my-[230px] md:my-[110px] lg:my-[105px] gap-y-4;
}

.contact-btn {
  @apply w-fit bg-accent px-5 py-3 rounded-3xl border-4 border-white text-white text-[17.5px] hover:bg-transparent hover:bg-accent transition-all;
}

.position-element {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.img-size {
  @apply md:max-w-[172.5px] lg:max-w-[228.3px] xl:max-w-[289.5px];
}

.center-elements {
  @apply xl:bottom-[-220px] md:bottom-[-135px];
}

.img {
  opacity: 1;
  mix-blend-mode: normal;
  background-image: url(@/assets/images/heroheader5-kopie-p-2000.png);
  background-position: 0 0;
  background-size: cover;
  margin: auto;
  transition: opacity 0.2s;
}

@media screen and (max-width: 479px) {
  .img {
    background-position: 60% 40%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (max-width: 991px) {
  .img {
    background-position: 60% 40%;
    background-size: cover;
  }
}
</style>
