<template>
  <footer class="bg-lightGray bottom-0 font-barlow py-8">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <!-- Contact Information -->
        <div class="justify-center w-full items-center flex flex-col space-y-2">
          <p class="min-w-max gap-2 items-center inline-flex">
            <i class="fa-solid fa-phone w-max"></i
            ><a href="tel:+4921025655540">+49 2102 5655540</a>
          </p>
          <p class="min-w-max gap-2 items-center inline-flex">
            <i class="fa-solid fa-envelope w-max"></i>
            <a href="mailto:support@wx1.de" target="_blank">support@wx1.de</a>
          </p>
          <p class="min-w-max gap-2 items-center inline-flex">
            <i class="fa-solid fa-location-dot w-max"></i>Kokkolastr. 5, 40882
            Ratingen
          </p>
        </div>

        <!-- Links -->
        <div class="w-full text-center justify-center h-min inline-flex flex-wrap space-x-1">
          <a class="w-min p-1 m-0" href="/dataprotection">Datenschutz</a>
          <span class="text-gray-500 w-min p-1 m-0">|</span>
          <a class="w-min p-1 m-0" href="/imprint">Impressum</a>
        </div>

        <!-- Logo -->
        <div class="justify-center w-full items-center flex">
          <img
            src="../assets/images/wx-one_official_header.svg"
            alt="WX-ONE"
            class="h-16"
          />
        </div>
      </div>

      <!-- Copyright -->
      <div class="text-center mt-8">
        © 2025 WizardTales GmbH. Alle Rechte vorbehalten.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  computed: {
    overviewList() {
      return [] // this.$i18n.getLocaleMessage(
      //  this.$i18n.locale
      // ).footer.list
    },
    externalLinks() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).footer.externalLinks
    }
  }
}
</script>

<style scoped lang="postcss">
.footer {
  @apply text-sm mb-[70px] md:mb-[5px] lg:mb-[50px] xl:mb-[0px];
}
</style>
