<template>
  <div class="sm:px-[15px] md:container md:mx-auto lg:px-[70px] xl:container xl:mx-auto xl:px-[170px] py-10 px-[20px] cursor-default font-barlow">
    <h1 class="title font-bold pb-10 leading-none">
      {{ $t('backupRecoveryPage.unbeatable1') }} <br> {{ $t('backupRecoveryPage.unbeatable2') }} <br> {{ $t('backupRecoveryPage.unbeatable3') }}
    </h1>
    <div class="overflow-y-auto rounded-lg">
      <table class="bg-[#2a3d91] overscroll-y-auto">
        <!-- head -->
        <thead>
          <tr>
            <th
              v-for="(item, index) in header"
              :key="index"
              class="p-2 xl:p-5 font-bold text-white header text-start text-nowrap"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <!-- body -->
        <tbody class="border border-gray-300">
          <tr
            v-for="(row, index) in dataArray"
            :key="index"
            class="border-b-2 bg-white"
          >
            <td class="xl:p-5 rows font-semibold">
              {{ row.title }}
            </td>
            <td
              v-for="(col, colIndex) in row.cols"
              :key="colIndex"
              class="p-0 xl:p-5"
            >
              <img
                :src="getImageForCol(col)"
                alt="icon"
                class="h-6 w-6 lg:h-10 lg:w-10 mx-auto"
                v-if="getImageForCol(col)"
              />
            </td>
          </tr>
          <tr class="text-white">
            <td>
              <h4 class="font-bold overview row">
                {{ $t('backupRecoveryPage.priceOverview') }}
              </h4>
              <p class="row-description">
                {{ $t('backupRecoveryPage.includesPrices') }}
              </p>
            </td>
            <td>
              <h5 class="font-bold overview2 wx-price text-nowrap">5,67 €*</h5>
              <p class="price-dsecription">per TiB/mo.</p>
              <p class="price-dsecription">0.006192 €</p>
            </td>
            <td>
              <h5 class="font-bold overview2 others text-nowrap">6,47 €*</h5>
              <p class="price-dsecription">per TiB/mo.</p>
              <p class="price-dsecription">0,00 €</p>
            </td>
            <td>
              <h5 class="font-bold overview2 others text-nowrap">27,30 €*</h5>
              <p class="price-dsecription">per TiB/mo.</p>
              <p class="price-dsecription">0.083333 €</p>
            </td>
            <td>
              <h5 class="font-bold overview2 others text-nowrap">33,80 €*</h5>
              <p class="price-dsecription">per TiB/mo.</p>
              <p class="price-dsecription">0.080556 €</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      header: ['Facts', 'WX-ONE', 'WASABI', 'AWS', 'AZURE'],
    }
  },
  computed: {
    dataArray() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).backupRecoveryPage.priceComparisons;
    }
  },
  methods: {
    getImageForCol(col) {
      const imageMap = {
        1: require('@/assets/images/backup-recovery/3_1.png'),
        0: require('@/assets/images/backup-recovery/3_2.png'),
        none: require('@/assets/images/backup-recovery/3_4.png'),
        w: require('@/assets/images/backup-recovery/3_3.png')
      }
      return imageMap[col] || null
    }
  }
}
</script>

<style scoped lang="postcss">
td {
  padding: 15px;
}

.title {
  @apply text-[30px] sm:text-[40px] md:text-[40px] lg:text-[60px];
}

.header {
  @apply text-[8px] sm:text-[14px] md:text-[18px] lg:text-[20px];
}

.rows {
  @apply text-[7px] sm:text-[12px] md:text-[18px] lg:text-[18px];
}

.row {
  @apply text-[8px] sm:text-[16px] md:text-[20px] lg:text-[20px] xl:text-[25px];
}

.row-description {
  @apply text-[4px] sm:text-[10px] md:text-[14px] lg:text-[14px];
}

.wx-price {
  @apply text-[8px] sm:text-[20px] md:text-[30px] lg:text-[34px];
}

.price-dsecription {
  @apply text-[4px] sm:text-[10px] md:text-[14px] lg:text-[14px];
}

.others {
  @apply text-[6px] sm:text-[15px] md:text-[20px];
}
</style>
