<template>
  <div class="cursor-default font-barlow">
    <!-- Hero Header -->
    <div id="main" class="relative margin-top-hero">
      <div class="container mx-auto font-barlow">
        <div class="flex flex-col pt-[135px] md:py-[0px]">
          <div>
            <h1 class="title text-left max-w-2xl">
              {{ this.servicesDetails.title }}
            </h1>
          </div>
          <div class="mt-4">
            <ul class="flex flex-col text-base text-black w-max">
              <li v-for="(check,index) in this.servicesDetails.checkList" :key="`checkList-${index}`" class="gap-4 inline-flex items-center w-max">
                <i class="fas fa-check text-2xl font-bold text-accent w-max" aria-hidden="true"></i>
                <span class="!font-medium">{{check}}</span>
              </li>
            </ul>
          </div>
          <div class="w-fit">
            <RouterLink :to="'/contact-us'" class="btn w-fit">
              {{ $t('homepage.banner.button') }}
            </RouterLink>
          </div>
          <div v-if="this.$route.params.name === 'cloud-solutions' || this.$route.params.name === 'cloud-losungen'">
            <img
              src="@/assets/images/homepage/iso.png"
              alt="iso"
              class="w-[250px] md:w-[350px]"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="service-content transition-all duration-1000 transform translate-y-20 opacity-0 slide-up-element"
    >
      <CloudSolutionsComponent v-if="showCloudSolution()" />
      <DataRecoveryComponent v-if="showDataRecovery()" />
    </div>
  </div>
  <ScrollToTop />
</template>

<script>
import ScrollToTop from '@/components/ScrollToTop.vue'
import DOMPurify from 'dompurify'
import { nextTick } from 'vue'
import DataRecoveryComponent from '@/components/Services/DataRecoveryComponent.vue'
import CloudSolutionsComponent from '@/components/Services/CloudSolutionsComponent.vue'

export default {
  data() {
    return {
      servicesDetails: {},
      lastScrollY: 0
    }
  },
  components: {
    ScrollToTop,
    DataRecoveryComponent,
    CloudSolutionsComponent
  },
  created() {
    document.title = this.servicesDetails.title + ' — WX-ONE'
    this.serviceData()
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0, 0)
    })
    window.addEventListener('scroll', this.handleScroll)
    // Initial check for elements
    this.handleScroll()
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    services() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).servicePage.services
    },
    additionalServices() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).servicePage
        .additionalServices
    },
    sanitizedHtml() {
      return DOMPurify.sanitize(this.servicesDetails.description)
    },
  },
  methods: {
    serviceData() {
      this.servicesDetails = this.services.find(
        (service) => service.serviceRoute === this.$route.params.name
      )
      sessionStorage.setItem(
        'serviceOtherLanguage',
        this.servicesDetails.otherLanguageRoute
      )
    },
    showCloudSolution() {
      return ['cloud-losungen', 'cloud-solutions'].includes(this.$route.params.name)
    },
    showDataRecovery() {
      return ['backup_recovery', 'backup-recovery'].includes(this.$route.params.name)
    },
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    },
    handleScroll() {
      const currentScrollY = window.scrollY
      const elements = document.querySelectorAll('.slide-up-element')

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect()
        const triggerPoint = window.innerHeight * 0.8 // Trigger when element is 80% in view

        if (rect.top < triggerPoint) {
          el.classList.add('slide-up-visible')
        }
      })

      this.lastScrollY = currentScrollY
    }
  },
  watch: {
    '$route.params.name': {
      handler(newName) {
        if (newName) {
          this.serviceData()
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
  color: #000000;
}

#main {
  background-image: url(@/assets/images/wx-one-home-head-cloud.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  background-position: 50% 50%;
  padding-top: 50px;
  padding-bottom: 90px;
}

@media screen and (max-width: 479px) {
  #main {
    background-image: url(@/assets/images/homepage/wx-one-website-head-cloud-bg-mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 120px;
    background-position: 50% 0%;
  }

  .title {
    line-height: 40px;
  }
}

@media screen and (min-width: 768px) {
  .title {
    line-height: 65px;
  }
}

.btn {
  background-color: rgb(47, 196, 210);
  color: #ffffff;
}

.btn {
  text-transform: uppercase;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 8px 12px;
  line-height: 1.25;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 0px;
  padding: 16px 40px 16px 40px;
  transform-origin: 50% 50%;
  width: fit-content;
}

.btn:hover {
  background-color: rgb(84, 177, 185);
  color: #ffffff;
}
</style>

<style scoped lang="postcss">
.title {
  @apply text-[35px] md:text-[60px];
}

.margin-top-hero {
  @apply mt-[90px] text-[45px] lg:text-[65px] xl:text-[90px] font-bold text-white text-center p-0 m-0 w-full;
}

.hero-img {
  @apply h-[279px];
}

.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  @apply text-gray-800 text-[14px] font-normal leading-[1.6em] text-start;
}

.relative-wrapper {
  @apply relative flex-1;
}

.side-content-position {
  @apply w-full lg:w-[286px] xl:w-[370px] min-h-[100px] overflow-auto text-lg pb-9;
}

.contact-heading {
  @apply md:text-[24px] lg:text-[27px] font-extrabold;
}

.contact-details {
  @apply w-fit text-gray-700 text-[16px];
}

.for-advice {
  @apply w-fit text-[17.5px];
}

.additional-service {
  @apply text-[20px] font-extrabold border-b-4 border-accent pl-8 pb-3;
}

.services {
  @apply text-[14px];
}

.always-ready {
  @apply text-[16px] sm:text-[22px] md:text-[36px] xl:text-[45px] font-black;
}

.always-ready-content {
  @apply text-[#8e8f9a] text-[14px] md:text-[18px] xl:text-[16px];
}

.contact-btn {
  @apply text-[17.5px] bg-accent text-white font-bold w-fit px-[25px] py-[20px] rounded-3xl border-2 border-accent hover:text-accent hover:bg-white transition-all duration-300;
}

.slide-up-element {
  opacity: 0;
  transform: translateY(40px);
  transition: all 1s ease-out;
  visibility: hidden;
}

.slide-up-element.slide-up-visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.btn {
  @apply text-[12px] md:text-[16px];
}

</style>
