<template>
  <section class="bg-lightGray">
    <div class="container mx-auto px-4 py-16 text-center">
      <h1 class="text-4xl font-bold mb-2 uppercase">{{ $t('homepage.section3.title') }}</h1>
      <h2 class="text-4xl font-bold mb-8 uppercase">{{ $t('homepage.section3.title2') }}</h2>

      <p class="max-w-4xl mx-auto mb-16 text-lg">
        {{ $t('homepage.section3.description') }}
      </p>

      <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <!-- Column 1 -->
        <div class="flex flex-col items-center">
          <div class="text-accent mb-6">
            <span
              role="img"
              :aria-label="$t('homepage.section3.columns[0].name')"
              ><i class="fas fa-arrow-trend-up text-4xl" aria-hidden="true"></i
            ></span>
          </div>
          <h3 class="text-xl px-4 text-gray-600 font-semibold mb-4">
            {{  $t('homepage.section3.columns[0].name') }}
          </h3>
          <p class="text-gray-600">
            {{  $t('homepage.section3.columns[0].description') }}
          </p>
        </div>

        <!-- Column 2 -->
        <div class="flex flex-col items-center">
          <div class="text-accent mb-6">
            <span
              role="img"
              :aria-label="$t('homepage.section3.columns[1].name')"
              ><i class="fas fa-headset text-4xl"></i
            ></span>
          </div>
          <h3 class="text-xl  text-gray-600 font-semibold mb-4">
            {{  $t('homepage.section3.columns[1].name') }}
          </h3>
          <p class="text-gray-600">
            {{  $t('homepage.section3.columns[1].description') }}
          </p>
        </div>

        <!-- Column 3 -->
        <div class="flex flex-col items-center">
          <div class="text-accent mb-6">
            <span
              role="img"
              :aria-label="$t('homepage.section3.columns[2].name')"
              ><i class="fas fa-cloud text-4xl" aria-hidden="true"></i
            ></span>
          </div>
          <h3 class="text-xl  text-gray-600 font-semibold mb-4">{{  $t('homepage.section3.columns[2].name') }}</h3>
          <p class="text-gray-600">
            {{  $t('homepage.section3.columns[2].description') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section3Component'
}
</script>

<style scoped lang="postcss">
</style>
