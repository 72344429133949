<template>
  <div class="mx-auto pb-8 font-barlow">
    <div
      class="banner flex uppercase justify-center items-center h-96 main text-4xl lg:text-5xl font-black pt-16 lg:pt-20 bg-"
    >
      <h1 class="max-w-6xl mx-auto px-4 my-8 text-2xl font-bold mb-6">
        Impressum
      </h1>
    </div>

    <address class="max-w-6xl mx-auto px-4 not-italic mb-6">
      WizardTales GmbH<br />
      Kokkolastr. 5<br />
      40882 Ratingen<br /><br />
      Telefon: +49 2102 5655540<br />
      E-Mail: hey@wizardtales.com
    </address>

    <section class="mb-6">
      <h2 class="text-xl font-semibold mb-2">Vertreten durch:</h2>
      <p>Geschäftsführer Tobias Gurtzick</p>
    </section>

    <section class="mb-6">
      <h2 class="text-xl font-semibold mb-2">Registereintrag:</h2>
      <p>
        Eingetragen im Handelsregister.<br />
        Registergericht: Amtsgericht Düsseldorf<br />
        Registernummer: HRB 84140
      </p>
    </section>

    <section class="mb-6">
      <h2 class="text-xl font-semibold mb-2">Umsatzsteuer-ID:</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:<br />
        DE319769833
      </p>
    </section>

    <section class="mb-6">
      <h2 class="text-xl font-semibold mb-2">Berufshaftpflichtversicherung:</h2>
      <p>
        Markel Insurance SE<br />
        Sophienstraße 26, 80333 München<br />
        Geltungsbereich: Weltweit
      </p>
    </section>

    <section class="mb-6">
      WizardTales ® und wzrdtales ® sind eingetragene Marken der WizardTales
      GmbH.
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Hinweis gemäß Online-Streitbeilegungs-Verordnung
      </h2>
      <p class="mb-4">
        Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz
        der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für
        die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein
        Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist
        die Europäische Kommission zuständig. Die Europäische
        Online-Streitbeilegungs-Plattform ist hier zu finden:
        <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>. Unsere
        E-Mail lautet: hey@wizardtales.com
      </p>
      <p class="mb-4">
        Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am
        Streitbeilegungsverfahren im Rahmen der Europäischen
        Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
        Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">
        Disclaimer – rechtliche Hinweise
      </h2>

      <h3 class="text-lg font-medium mb-2">§ 1 Warnhinweis zu Inhalten</h3>
      <p class="mb-4">
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit
        größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt
        jedoch keine Gewähr für die Richtigkeit und Aktualität der
        bereitgestellten kostenlosen und frei zugänglichen journalistischen
        Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die
        Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
        wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen
        Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem
        Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des
        Anbieters.
      </p>

      <h3 class="text-lg font-medium mb-2">§ 2 Externe Links</h3>
      <p class="mb-4">
        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
        Links"). Diese Websites unterliegen der Haftung der jeweiligen
        Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen
        Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
        bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der
        Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige
        Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von
        externen Links bedeutet nicht, dass sich der Anbieter die hinter dem
        Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige
        Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise
        auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
        werden jedoch derartige externe Links unverzüglich gelöscht.
      </p>

      <h3 class="text-lg font-medium mb-2">
        § 3 Urheber- und Leistungsschutzrechte
      </h3>
      <p class="mb-4">
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem
        deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber-
        und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der
        vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
        Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
        Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
        von Inhalten in Datenbanken oder anderen elektronischen Medien und
        Systemen. Inhalte und Rechte Dritter sind dabei als solche
        gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
        einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
        strafbar. Lediglich die Herstellung von Kopien und Downloads für den
        persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
      </p>
      <p class="mb-4">
        Die Darstellung dieser Website in fremden Frames ist nur mit
        schriftlicher Erlaubnis zulässig.
      </p>

      <h3 class="text-lg font-medium mb-2">
        § 4 Besondere Nutzungsbedingungen
      </h3>
      <p class="mb-4">
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
        den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
        ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
        Einzelfall die besonderen Nutzungsbedingungen.
      </p>

      <p class="text-sm text-gray-600">
        Quelle: Impressum Muster von JuraForum.de
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ImprintPage'
}
</script>

<style scoped>
.banner {
  background-image: url(@/assets/images/wx-one-home-head-cloud.jpg);
  padding-top: 40px;
  padding-right: 0px;
  padding-bottom: 30px;
  padding-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  background-position: 50% 48.5%;
  margin-top: 90px;
}

section {
  @apply max-w-6xl mx-auto px-4;
}
</style>
