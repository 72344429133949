<template>
  <div class="container mx-auto font-barlow">
    <div
      class="flex flex-col justify-center items-center lg:flex-row lg:justify-around gap-y-5 lg:gap-x-20 py-10 lg:py-0"
    >
      <img
        src="../../assets/images/wx-one_official_header.svg"
        alt="Wizardtales"
        class="h-28 w-[280px] lg:h-[400px] lg:w-[400px] text-white"
      />
      <div
        class="flex flex-col text-center md:text-start text-base text-wrap w-fit gap-y-5"
      >
        <h1 class="text-3xl font-bold">
          WX-ONE by <br />
          WizardTales GmbH
        </h1>
        <div class="flex flex-col gap-y-2">
          <h5 class="text-lg font-semibold">{{ $t('contactPage.address') }}</h5>
          <p class="text-gray-500 text-sm">WX-ONE by WizardTales GmbH</p>
        </div>
        <div class="flex flex-col gap-y-2">
          <h5 class="text-lg font-semibold">
            {{ $t('contactPage.generalEnquiry') }}
          </h5>
          <p class="text-gray-500 text-sm">kontakt@wx1.de</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
