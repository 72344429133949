<template>
  <div class="bg-white w-full xl:container xl:mx-auto px-[20px] cursor-default font-barlow">
    <h2
      class="heading font-extrabold text-center py-[40px]"
    >
    {{ $t('backupRecoveryPage.whyChoose') }}
    </h2>

    <div class="w-full">
      <div class="relative w-full shadow-2xl rounded-lg p-5 lg:p-[105px] border border-[#8e8f9a]">
        <!-- Card Transition -->
        <transition name="fade" mode="out-in">
          <!-- Rendering the active card -->
          <div
            v-if="everythingYouNeed[activeCard1]"
            :key="everythingYouNeed[activeCard1].id"
            class="w-full mx-3 text-black pattern"
          >
            <div class="flex flex-col md:flex-row justify-center items-center">
              <div>
                <img
                  :src="getImage(everythingYouNeed[activeCard1].imageLink)"
                  class="h-96 w-96"
                />
              </div>
              <div class="flex flex-col gap-y-5 text-center md:text-start">
                <h2 class="font-extrabold title">
                  {{ everythingYouNeed[activeCard1].title }}
                </h2>
                <p class="text-gray-500 description leading-relaxed">
                  {{ everythingYouNeed[activeCard1].description }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <!-- Pagination -->
        <div class="flex justify-center mt-4 space-x-2">
          <div
            v-for="(card, index) in everythingYouNeed"
            :key="index"
            class="h-6 w-6 rounded-full bg-gray-400 cursor-pointer text-white text-center"
            :class="{
              'bg-gray-800 border-2 border-black text-white': activeCard1 === index
            }"
            @click="goToCard1(index)"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCard1: 0,
      intervalId: null,
    }
  },
  mounted() {
    this.startAutoSlide()
  },
  beforeUnmount() {
    this.stopAutoSlide()
  },
  computed: {
    containerStyle2() {
      return {
        transform: `translateX(-${this.activeCard1 * (33.3 / 1)}%)`,
        width: `${this.everythingYouNeed.length * (100 / 1)}%`
      }
    },
    everythingYouNeed() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).backupRecoveryPage.points;
    }
  },

  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    },
    goToCard1(index) {
      this.activeCard1 = index
    },
    startAutoSlide() {
      this.intervalId = setInterval(() => {
        this.activeCard1 =
          (this.activeCard1 + 1) % this.everythingYouNeed.length
      }, 10000)
    },
    stopAutoSlide() {
      clearInterval(this.intervalId)
    }
  }
}
</script>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.heading {
  @apply text-[20px] sm:text-[32px] md:text-[36px] xl:text-[45px];
}

.title {
  @apply text-[14px] md:text-[25px] lg:text-[35px];
}

.description {
  @apply text-[12px] sm:text-[12px] md:text-[20px] lg:text-[30px];
}

.pattern:nth-child(odd) {
  flex-direction: row-reverse;
}

.pattern:nth-child(even) {
  flex-direction: row;
}

</style>
