<template>
  <div>
    <HeroHeader />
    <ScrollAnimation>
      <BlogCards />
    </ScrollAnimation>
    <ScrollToTop />
  </div>
</template>

<script>
import HeroHeader from '@/components/BlogPage/HeroHeader.vue'
import BlogCards from "@/components/BlogPage/BlogCards.vue"
import { nextTick } from 'vue';
import ScrollAnimation from '@/components/ScrollAnimation.vue'
import ScrollToTop from '@/components/ScrollToTop.vue';

export default {
  components: {
    HeroHeader,
    BlogCards,
    ScrollAnimation,
    ScrollToTop,
  },
  created() {
    document.title = 'Unser Blog — WX-ONE'
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0,0);
    })
  },
}
</script>

<style scoped>
  @media (max-width: 767px) {
    .transition-all {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
</style>
