<template>
  <!-- Hero Header -->
  <div
    class="flex justify-center items-center text-center main font-bold text-[#141733] hero-header mt-[90px] md:mt-[40px] lg:mt-[55px] xl:mt-[90px] cursor-default font-barlow capitalize"
  >
  {{ $t('blogPage.title') }}
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="postcss">
.main {
  background-color: rgba(142, 143, 154, .15);
}
.hero-header {
  @apply text-[45px] md:text-[65px] lg:text-[65px] xl:text-[90px] h-60 py-[165px] lg:py-[125px];
}
</style>
