<template>
  <section id="main">
    <div class="container mx-auto font-barlow">
      <div class="flex flex-col gap-y-5 pt-[135px] md:py-[0px]">
        <div>
          <h2 class="title max-w-md md:max-w-2xl !font-medium">
            {{ $t('homepage.banner.title') }}
          </h2>
        </div>
        <div>
          <div class="sub-title w-[350px] xl:w-[450px]">
            <ul class="text-lg">
              <li
                v-for="(check, index) in checkList"
                :key="`checkList-${index}`"
                class="flex gap-4 items-center"
              >
                <i
                  class="fas fa-check text-2xl text-accent w-max"
                  aria-hidden="true"
                ></i>
                <span>{{ check }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <RouterLink :to="'/contact-us'" class="btn w-fit">
              {{ $t('homepage.banner.button') }}
            </RouterLink>
          </div>
        </div>
        <div>
          <img
            src="@/assets/images/homepage/iso.png"
            alt="iso"
            class="w-[250px] md:w-[350px]"
          />
        </div>
      </div>

      <!-- <div
        class="hidden md:block center-elements position-element container mx-auto z-30 mb-14 transform transition-all duration-1000"
      >
        <div class="flex flex-col gap-y-5 md:flex-row">
          <router-link
            v-for="item in section2Data"
            :key="item.id"
            :to="`/services/${item.serviceRoute}`"
          >
            <div class="text-center flex flex-col items-center">
              <ScrollAnimation>
                <div
                  class="flex flex-col justify-center items-center hover:text-accent"
                >
                  <img
                    :src="getImage(item.imageLink)"
                    alt="item.name"
                    class="h-auto img-size"
                  />
                  <h3 class="font-bold text-xl">{{ item.name }}</h3>
                </div>
              </ScrollAnimation>
            </div>
          </router-link>
        </div>
      </div> -->
    </div>
  </section>
  <div>
    <div class="bg-[#F9FAFA]">
      <div
        class="tech container mx-auto flex flex-row justify-center items-center py-[40px]"
      >
        <div>
          <img
            class="cards"
            src="@/assets/images/homepage/kubernetes-logo.png"
            alt="Kubernetes"
            width="500"
            height="88"
            loading="lazy"
          />
        </div>
        <!--<div>
          <img
            class="cards"
            src="@/assets/images/homepage/gitlab-logo.png"
            alt="GitLab"
            width="300"
            height="100"
            loading="lazy"
          />
        </div>-->
        <div class="hidden md:block">
          <img
            class="cards"
            src="@/assets/images/homepage/element-logo.png"
            alt="Element"
            width="300"
            height="100"
            loading="lazy"
          />
        </div>
        <div>
          <img
            class="cards"
            src="@/assets/images/homepage/founderfruit-logo.svg"
            alt="founderfruit"
            style="height: 50px"
            loading="lazy"
          />
        </div>
        <div>
          <img
            class="cards"
            src="@/assets/images/homepage/ryskex.svg"
            alt="ryskex"
            style="height: 55px"
            loading="lazy"
          />
        </div>
        <!--<div class="hidden md:block">
          <img
            class="cards"
            src="@/assets/images/homepage/sonarqube-logo.png"
            alt="Sonarqube"
            width="300"
            height="100"
            loading="lazy"
          />
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import ScrollAnimation from '../ScrollAnimation.vue'
export default {
  name: 'HeroHeader',
  components: {
    // ScrollAnimation
  },
  created() {
    document.title = 'WX-ONE - Die neue Cloud aus Deutschland'
  },
  data() {
    return {}
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  },
  computed: {
    section2Data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).homepage.imageInfo
    },
    checkList() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).homepage.banner
        .check
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 800;
}

.sub-title {
  font-weight: 400;
}

#main {
  padding-top: 40px;
  padding-right: 0px;
  padding-bottom: 30px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000000;
}

#main {
  background-image: url(@/assets/images/wx-one-home-head-cloud.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  background-position: 50% 48.5%;
  margin-top: 90px;
}

.btn {
  background-color: rgb(47, 196, 210);
  color: #ffffff;
}

.btn {
  text-transform: uppercase;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 8px 12px;
  line-height: 1.25;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 0px;
  padding: 16px 40px 16px 40px;
  transform-origin: 50% 50%;
}

.btn:hover {
  background-color: rgb(84, 177, 185);
  color: #ffffff;
}

/* .cards {
  filter: grayscale(100%) opacity(75%);
}

.cards:hover {
  filter: grayscale(0%) opacity(100%);
} */

.cards {
  display: block;
  max-width: 100%;
  height: auto;
}

.cards {
  vertical-align: middle;
  transition: 0.4s;
}

@media screen and (max-width: 479px) {
  #main {
    background-image: url(@/assets/images/homepage/wx-one-website-head-cloud-bg-mobile.jpg);
    background-size: cover;
    padding-bottom: 40px;
    background-position: 50% 0%;
  }

  .title {
    line-height: 40px;
  }
}

@media screen and (min-width: 768px) {
  .title {
    line-height: 65px;
  }
}
</style>

<style scoped lang="postcss">
.position-element {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.img-size {
  @apply md:max-w-[172.5px] lg:max-w-[228.3px] xl:max-w-[289.5px];
}

.center-elements {
  @apply xl:bottom-[180px] md:bottom-[380px];
}

.title {
  @apply text-[35px] md:text-[60px];
}

.sub-title {
  @apply text-[14px] md:text-[22px];
}

.btn {
  @apply text-[12px] md:text-[16px];
}

.cards {
  @apply grayscale-[0%] opacity-[100%] md:grayscale-[100%] md:opacity-[75%] md:hover:grayscale-[0%] md:hover:opacity-[100%];
}
</style>
