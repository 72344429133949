<template>
  <div>
    <!-- Features Section -->
    <section class="bg-[#dae2ea] py-12 px-4">
      <div class="max-w-6xl mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div v-for="(feature, index) in features" :key="index" class="bg-white p-8 rounded shadow-sm h-74">
            <template v-if="index !== 5">
              <i :class="feature.icon + ' text-accent text-2xl mb-8'"></i>
              <h3 class="text-accent uppercase font-semibold text-sm mb-6">
                {{ feature.title }}
              </h3>
              <p class="text-gray-700 text-sm">
                {{ feature.description }}
              </p>
            </template>
            <template v-else>
              <div class="flex justify-center mb-4">
                <img src="/service/1.webp" alt="Cloud Storage" class="h-52 w-52 aspect-square" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- Specialties Section -->
    <section class="py-12 px-4">
      <div class="max-w-6xl mx-auto">
        <div class="flex flex-col md:flex-row items-center mb-12">
          <div class="md:w-1/2 mb-6 md:mb-0">
            <img src="/service/2.png" alt="Cloud Servers" class="mx-auto h-96 w-96" />
          </div>

          <div class="md:w-1/2">
            <h2 class="font-bold text-xl mb-10">{{ specialtiesTitle }}</h2>
            <ul class="space-y-6">
              <li v-for="(item, index) in specialties" :key="index" class="flex items-start">
                <div class="mr-4 mt-1 w-max">
                  <i :class="item.icon + ' text-2xl text-gray-700'"></i>
                </div>
                <div>
                  <h3 class="font-semibold mb-1">{{ item.title }}</h3>
                  <p class="text-sm text-gray-700">{{ item.description }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- CTA Section -->
    <section class="bg-primary text-white py-8 px-4">
      <div class="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
        <p class="mb-4 md:mb-0 text-center md:text-left">{{ ctaText }}</p>
        <button class="w-max bg-accent hover:bg-teal-600 text-white px-6 py-2 rounded-full text-sm transition">
          {{ ctaButton }}
        </button>
      </div>
    </section>

    <!-- Comparison Section -->
    <section class="py-12 px-4">
      <div class="max-w-6xl mx-auto">
        <h2 class="text-center font-bold text-xl mb-2">{{ comparisonTitle1 }}</h2>
        <h3 class="text-center font-bold text-lg mb-10">{{ comparisonTitle2 }}</h3>

        <div class="overflow-x-auto">
          <table class="min-w-full border-collapse">
            <thead>
              <tr>
                <th class="py-4 px-2 text-left"></th>
                <th v-for="provider in comparisonProviders" :key="provider" class="py-4 px-2 text-center">{{ provider }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in comparisonRows" :key="rowIndex" class="border-t">
                <td class="py-3 px-2">{{ row.feature }}</td>
                <td v-for="(provider, providerIndex) in comparisonProviders" :key="providerIndex"
                    :class="[
                      'py-3 px-2 text-center',
                      providerIndex === 0 ? 'bg-accent text-white' : '',
                      row.values[providerIndex] === '?' || row.values[providerIndex] === '!' ? 'text-gray-500' : '',
                      row.values[providerIndex] === '✓' && providerIndex !== 0 ? 'text-purple-500' : ''
                    ]">
                  <template v-if="row.values[providerIndex] === '✓'">
                    <i class="fas fa-check"></i>
                  </template>
                  <template v-else-if="row.values[providerIndex] === '✗'">
                    <i class="fas fa-times"></i>
                  </template>
                  <template v-else-if="row.values[providerIndex] === '?'">?</template>
                  <template v-else-if="row.values[providerIndex] === '!'">!</template>
                  <span v-else v-html="row.values[providerIndex]"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-4">
          <p v-for="(note, index) in comparisonNotes" :key="index" class="text-xs text-gray-500" :class="index === 0 ? 'w-max' : ''">
            <template v-if="note.icon">
              <i :class="note.icon + ' w-max'"></i>&nbsp;
            </template>
            {{ note.text }}
          </p>
        </div>
      </div>
    </section>

    <!-- Application Areas Section -->
    <section class="bg-primary text-white py-24 px-4">
      <div class="max-w-6xl mx-auto">
        <h2 class="text-center font-bold text-xl mb-10">{{ areasTitle }}</h2>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div v-for="(area, index) in applicationAreas" :key="index" class="text-center">
            <div class="flex justify-center mb-4">
              <i :class="area.icon + ' text-4xl text-accent'"></i>
            </div>
            <h3 class="font-semibold mb-4 text-accent">{{ area.title }}</h3>
            <p class="text-sm">{{ area.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'StorageServicesComponent',
  data() {
    return {
      localeData: {
        DE: {
          features: [
            {
              icon: "fas fa-map-marker-alt",
              title: "Lokal & Vertrauenswürdig",
              description: "Entwickelt und betrieben in Deutschland – Ihr Datenpartner in Herzen Europas"
            },
            {
              icon: "fas fa-server",
              title: "Zuverlässige Technologie",
              description: "Unterstützt den Industriestandard S3 für höchste Kompatibilität und Zuverlässigkeit."
            },
            {
              icon: "fas fa-list-check",
              title: "Datenintegrität",
              description: "11×9's Data durability – für digitaler Schutz Ist bei uns sicher"
            },
            {
              icon: "far fa-clock",
              title: "Maximale Verfügbarkeit",
              description: "Standard Verfügbarkeit von 99.9% mit Optionen für bis zu 99.99%"
            },
            {
              icon: "fas fa-cloud-upload",
              title: "Sofortiger Zugriff",
              description: "Daten jederzeit und sofort verfügbar – keine Wartezeiten, keine Kompromisse"
            },
            { icon: "", title: "", description: "" }
          ],
          specialtiesTitle: "BESONDERHEITEN",
          specialties: [
            {
              icon: "fas fa-user-secret",
              title: "DSGVO-Konformität",
              description: "Wir nehmen Datenschutz ernst. 100 % DSGVO-konform für Ihre und die Daten Ihrer Kunden."
            },
            {
              icon: "fas fa-box-archive",
              title: "Langeitspeicherung",
              description: "Langzeitspeicherung für mehr als 10 Jahre verfügbar – und das mit weiteren Einsparungen."
            },
            {
              icon: "fas fa-truck",
              title: "DiaT*-Recovery",
              description: "Einzigartige Recovery-Optionen, inklusive \"Data in a Truck\"-Service für schnelle Wiederherstellung bei großen Datenmengen."
            }
          ],
          ctaText: "Lassen Sie uns die digitale Transformation Ihres Unternehmens gemeinsam gestalten.",
          ctaButton: "BERATUNGSTERMIN VEREINBAREN",
          comparisonTitle1: "UNSCHLAGBARES PREIS-LEISTUNGSVERHÄLTNIS",
          comparisonTitle2: "UND MAXIMALE TRANSPARENZ",
          comparisonProviders: ["WX-ONE", "WASABI", "AWS", "AZURE"],
          comparisonRows: [
            { feature: "Entwickelt in Deutschland", values: ["✓", "✗", "✗", "✗"] },
            { feature: "Betrieben in Deutschland", values: ["✓", "?", "?", "?"] },
            { feature: "Industriestandard S3", values: ["✓", "✓", "✓", "✗"] },
            { feature: "11x9s Datenintegrität", values: ["✓", "✓", "✓", "✓"] },
            { feature: "99.9 % Standard Verfügbarkeit", values: ["✓", "✓", "!", "✓"] },
            { feature: "Bis zu 99.99 % Verfügbarkeit", values: ["✓", "✓", "!", "✓"] },
            { feature: "Daten jederzeit sofort verfügbar", values: ["✓", "✓", "✓", "✓"] },
            { feature: "Data Recovery Optionen", values: ["✓", "✗", "✗", "✗"] },
            { feature: "Long term Archive (>10 Jahre) mit weiteren Einsparungen", values: ["✓", "✗", "✓", "✓"] },
            { feature: "100 % DSGVO-konform", values: ["✓", "?", "?", "?"] },
            {
              feature: "Preisvergleich je TB/Monat",
              values: [
                "5,97 €*<br /><span class=\"text-sm\">Standard</span>",
                "6,47 €*<br /><span class=\"text-sm\">Alle</span>",
                "27,26 €*<br /><span class=\"text-sm\">Standard S3</span>",
                "23,89 €*<br /><span class=\"text-sm\">Standard</span>"
              ]
            }
          ],
          comparisonNotes: [
            {
              text: "* Alle Berechnungen wurden zwecks Vergleichbarkeit mit 20 % Traffic durchgeführt. Dies entspricht etwa dem 2-fachen Komplett-Download im Jahr."
            },
            {
              icon: "fas fa-question",
              text: "Keine sicheren Angaben"
            },
            {
              icon: "fas fa-exclamation",
              text: "Nicht bei den vergleichbaren Angeboten enthalten"
            }
          ],
          areasTitle: "ANWENDUNGSGEBIETE",
          applicationAreas: [
            {
              icon: "fas fa-cloud",
              title: "Backup & Recovery",
              description: "Wir nutzen modernste Technologien, um die Integrität Ihrer Daten zu gewährleisten. Dabei spielen wir Backups auf effiziente Weise und können diese im Notfall rasch wiederherstellen, um jeglichen Datenverlust zu vermeiden."
            },
            {
              icon: "fas fa-robot",
              title: "KI & Machine Learning",
              description: "Unser Object Storage Service bietet über unser Backup Angebot hinaus auch die hochmoderne High-Performance-Speicherkapazität und Geschwindigkeit, die für das Training und den Betrieb von KI Machine Learning-Modellen benötigt wird. So können Sie beim Entwickeln, effizient auf umfangreiche Datenmengen zugreifen und den Trainingsprozess zu optimieren."
            },
            {
              icon: "fas fa-file",
              title: "Multimedia Inhaltskataloge",
              description: "Unsere Object Storage Services können wir große Mengen an Multimedia-Inhalten speichern, zum Beispiel für die Sicherung des Videomaterial von Überwachungskameras. Dies garantiert Ihnen schnelle und zuverlässige Verfügbarkeit Ihrer Inhalte."
            }
          ]
        },
        EN: {
          features: [
            {
              icon: "fas fa-map-marker-alt",
              title: "Local & Trustworthy",
              description: "Developed and operated in Germany – Your data partner in the heart of Europe"
            },
            {
              icon: "fas fa-server",
              title: "Reliable Technology",
              description: "Supports the S3 industry standard for maximum compatibility and reliability."
            },
            {
              icon: "fas fa-list-check",
              title: "Data Integrity",
              description: "11×9's data durability – your digital protection is secure with us"
            },
            {
              icon: "far fa-clock",
              title: "Maximum Availability",
              description: "Standard availability of 99.9% with options for up to 99.99%"
            },
            {
              icon: "fas fa-cloud-upload",
              title: "Instant Access",
              description: "Data available anytime and immediately – no waiting times, no compromises"
            },
            { icon: "", title: "", description: "" }
          ],
          specialtiesTitle: "SPECIALTIES",
          specialties: [
            {
              icon: "fas fa-user-secret",
              title: "GDPR Compliance",
              description: "We take data protection seriously. 100% GDPR compliant for your data and your customers' data."
            },
            {
              icon: "fas fa-box-archive",
              title: "Long-term Storage",
              description: "Long-term storage available for more than 10 years – with additional savings."
            },
            {
              icon: "fas fa-truck",
              title: "DiaT*-Recovery",
              description: "Unique recovery options, including \"Data in a Truck\" service for fast recovery of large data volumes."
            }
          ],
          ctaText: "Let's shape the digital transformation of your company together.",
          ctaButton: "SCHEDULE CONSULTATION",
          comparisonTitle1: "UNBEATABLE PRICE-PERFORMANCE RATIO",
          comparisonTitle2: "AND MAXIMUM TRANSPARENCY",
          comparisonProviders: ["WX-ONE", "WASABI", "AWS", "AZURE"],
          comparisonRows: [
            { feature: "Developed in Germany", values: ["✓", "✗", "✗", "✗"] },
            { feature: "Operated in Germany", values: ["✓", "?", "?", "?"] },
            { feature: "Industry standard S3", values: ["✓", "✓", "✓", "✗"] },
            { feature: "11x9s data integrity", values: ["✓", "✓", "✓", "✓"] },
            { feature: "99.9% standard availability", values: ["✓", "✓", "!", "✓"] },
            { feature: "Up to 99.99% availability", values: ["✓", "✓", "!", "✓"] },
            { feature: "Data available anytime immediately", values: ["✓", "✓", "✓", "✓"] },
            { feature: "Data recovery options", values: ["✓", "✗", "✗", "✗"] },
            { feature: "Long term archive (>10 years) with additional savings", values: ["✓", "✗", "✓", "✓"] },
            { feature: "100% GDPR compliant", values: ["✓", "?", "?", "?"] },
            {
              feature: "Price comparison per TB/month",
              values: [
                "€5.97*<br /><span class=\"text-sm\">Standard</span>",
                "€6.47*<br /><span class=\"text-sm\">All</span>",
                "€27.26*<br /><span class=\"text-sm\">Standard S3</span>",
                "€23.89*<br /><span class=\"text-sm\">Standard</span>"
              ]
            }
          ],
          comparisonNotes: [
            {
              text: "* All calculations were made with 20% traffic for comparability. This corresponds to approximately twice the complete download per year."
            },
            {
              icon: "fas fa-question",
              text: "No reliable information"
            },
            {
              icon: "fas fa-exclamation",
              text: "Not included in comparable offers"
            }
          ],
          areasTitle: "APPLICATION AREAS",
          applicationAreas: [
            {
              icon: "fas fa-cloud",
              title: "Backup & Recovery",
              description: "We use state-of-the-art technologies to ensure the integrity of your data. We perform backups efficiently and can quickly restore them in case of emergency to avoid any data loss."
            },
            {
              icon: "fas fa-robot",
              title: "AI & Machine Learning",
              description: "Beyond our backup offering, our Object Storage Service provides the cutting-edge high-performance storage capacity and speed needed for training and operating AI machine learning models. This allows you to efficiently access extensive amounts of data during development and optimize the training process."
            },
            {
              icon: "fas fa-file",
              title: "Multimedia Content Catalogs",
              description: "Our Object Storage Services can store large amounts of multimedia content, for example for securing video material from surveillance cameras. This guarantees you fast and reliable availability of your content."
            }
          ]
        }
      },
      currentLocale: 'DE'
    };
  },
  computed: {
    features() {
      return this.localeData[this.currentLocale].features;
    },
    specialtiesTitle() {
      return this.localeData[this.currentLocale].specialtiesTitle;
    },
    specialties() {
      return this.localeData[this.currentLocale].specialties;
    },
    ctaText() {
      return this.localeData[this.currentLocale].ctaText;
    },
    ctaButton() {
      return this.localeData[this.currentLocale].ctaButton;
    },
    comparisonTitle1() {
      return this.localeData[this.currentLocale].comparisonTitle1;
    },
    comparisonTitle2() {
      return this.localeData[this.currentLocale].comparisonTitle2;
    },
    comparisonProviders() {
      return this.localeData[this.currentLocale].comparisonProviders;
    },
    comparisonRows() {
      return this.localeData[this.currentLocale].comparisonRows;
    },
    comparisonNotes() {
      return this.localeData[this.currentLocale].comparisonNotes;
    },
    areasTitle() {
      return this.localeData[this.currentLocale].areasTitle;
    },
    applicationAreas() {
      return this.localeData[this.currentLocale].applicationAreas;
    }
  },
  created() {
    this.updateLocale();
  },
  watch: {
    '$i18n.locale': function() {
      this.updateLocale();
    }
  },
  methods: {
    updateLocale() {
      this.currentLocale = this.$i18n.locale === 'DE' ? 'DE' : 'EN';
    }
  }
};
</script>
