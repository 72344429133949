<template>
    <div ref="elementRef" :class="[
      'transition-all duration-1000 transform',
      isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
    ]">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ScrollAnimation',
    data() {
      return {
        isVisible: false,
        observer: null
      }
    },
    mounted() {
      this.observer = new IntersectionObserver(this.observerCallback, {
        root: null,
        threshold: 0.1
      });
      
      if (this.$refs.elementRef) {
        this.observer.observe(this.$refs.elementRef);
      }
    },
    beforeUnmount() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    methods: {
      observerCallback(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.isVisible = true;
            // Optional: Unobserve after animation
            // this.observer.unobserve(entry.target);
          }
        });
      }
    }
  }
  </script>