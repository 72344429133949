<template>
  <!-- Hero Header -->
  <div class="relative main font-barlow cursor-default">
    <img
      src="@/assets/images/about-us/team7.png"
      class="object-cover h-[375px] mt-20"
    />

    <h1
      class="centered-element text-center title font-bold pt-5 text-white"
    >
      {{ $t('aboutus.title') }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'HeroHeader',
  created() {
    document.title = 'About Us — WX-ONE'
  }
}
</script>

<style scoped lang="postcss">
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.title {
  @apply text-[30px] sm:text-[45px] md:text-[65px] lg:text-[90px];
}
</style>
