<template>
  <!-- <HeroHeader /> -->
  <HeroHeader_v2 />

  <ScrollAnimation>
    <Section2Component class="hidden" />
  </ScrollAnimation>

  <ScrollAnimation>
    <Section3Component />
  </ScrollAnimation>

  <ScrollAnimation>
    <Section4Component />
  </ScrollAnimation>

  <ScrollAnimation>
    <Section5Component />
  </ScrollAnimation>

  <ScrollAnimation>
    <ContactForm />
  </ScrollAnimation>

  <ScrollToTop />
</template>

<script>
// import HeroHeader from '@/components/Homepage/HeroHeader.vue'
import HeroHeader_v2 from '@/components/Homepage/HeroHeader_v2.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import Section2Component from '@/components/Homepage/Section2Component.vue'
import Section3Component from '@/components/Homepage/Section3Component.vue'
import Section4Component from '@/components/Homepage/Section4Component.vue'
import Section5Component from '@/components/Homepage/Section5Component.vue'
import ContactForm from '@/components/ContactUs/ContactForm.vue'
import ScrollAnimation from '@/components/ScrollAnimation.vue'
import { nextTick } from 'vue'

export default {
  components: {
    // HeroHeader,
    Section2Component,
    Section3Component,
    Section4Component,
    Section5Component,
    ContactForm,
    ScrollAnimation,
    HeroHeader_v2,
    ScrollToTop,
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0,0);
    })
  },
}
</script>
