<template>
  <!-- Sidebar for Large screens -->

  <!-- About WX-ONE -->
  <div>
    <div
      v-if="AboutBtn"
      @click="toggleSidebar"
      class="fixed inset-0 bg-transparent z-30 font-barlow"
    ></div>
    <div
      :class="['sidebar', { 'show-sidebar': AboutBtn }]"
      class="hidden lg:fixed lg:top-0 lg:left-0 lg:right-0 lg:block bg-white transition-all ease-in-out duration-300 z-40 shadow-xl"
    >
      <div class="container mx-auto">
        <div class="flex gap-3">
          <div style="padding-top: 130px" class="flex gap-x-6">
            <div>
              <div class="text-base font-medium border-b-2 pl-5 cursor-default">
                <h1>{{ $t('navBarDropDown.informationTitle') }}</h1>
              </div>

              <router-link
                v-for="item in aboutInformation"
                :key="item.id"
                :to="item.link"
                @click="toggleBoolean"
              >
                <div
                  class="py-5 text-base hover:text-accent p-5 rounded"
                >
                  <button class="w-fit text-start">
                    <div>
                      <span class="py-1 font-bold">
                        <b>{{ item.name }} </b>
                      </span>
                      <p class="text-sm py-1">
                        {{ item.description }}
                      </p>
                    </div>
                  </button>
                </div>
              </router-link>
            </div>

            <div>
              <div class="text-base font-medium border-b-2 pl-5 cursor-default">
                <h1>{{ $t('navBarDropDown.weEverywhereTitle') }}</h1>
              </div>

              <div
                v-for="item in aboutWeEveryWhere"
                :key="item.id"
                class="py-5 text-base hover:text-accent p-5 rounded"
              >
                <a href="https://www.linkedin.com/company/wx-one/">
                  <button class="w-fit text-start">
                    <div>
                      <span class="py-1 font-bold">
                        <b>{{ item.name }} </b>
                      </span>
                      <p class="text-sm py-1">
                        {{ item.description }}
                      </p>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div class="bg-gray-100 pt-3" style="padding-top: 130px">
            <div class="px-4">
              <div class="text-base font-medium border-b-2 cursor-default">
                <h1>{{ $t('navBarDropDown.contentFromOurHouseTitle') }}</h1>
              </div>

              <div
                v-for="item in aboutBlogPosts"
                :key="item.id"
                class="font-medium py-5 text-base"
              >
                <RouterLink
                  :to="`/blog-post/${item.blogRoute}`"
                  @click="toggleBoolean"
                >
                  <div
                    class="flex gap-3 text-start p-3 hover:shadow-lg hover:cursor-pointer bg-white w-full transition-all duration-300 group"
                  >
                    <div class="w-fit">
                      <img
                        :src="getImage(item.blogImage)"
                        class="h-16 w-16 object-top object-cover"
                        alt=""
                      />
                    </div>
                    <div>
                      <span
                        class="text-gray-400 text-sm font-semibold tracking-wide"
                        >{{ $t('navBarDropDown.blogTitle') }}</span
                      >
                      <p class="text-xs py-2 flex-wrap text-[#141733]">
                        {{ item.title }}
                      </p>
                      <div class="py-2 flex items-center justify-between">
                        <div
                          class="text-gray-500 text-base font-semibold group-hover:text-accent"
                        >
                          {{ $t('navBarDropDown.readMoreTitle') }}
                        </div>
                        <div class="w-fit">
                          <svg
                            class="h-3 w-3"
                            viewBox="0 0 14 11"
                            preserveAspectRatio="xMinYMin"
                            style="fill: #646f79"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M9.827.144L9.019.86l3.256 3.738H0V5.69h12.275L9.02 9.427l.808.717 4.173-5-4.173-5z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-10 border-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    AboutBtn: Boolean
  },
  computed: {
    aboutInformation() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.information
    },
    aboutWeEveryWhere() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.weEverywhere
    },
    aboutBlogPosts() {
      return this.$i18n.getLocaleMessage(
        this.$i18n.locale
      ).navBarDropDown.blogPosts
    }
  },
  methods: {
    toggleBoolean() {
      const newValue = !this.AboutBtn
      this.$emit('update-about', newValue)
    },
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    },
    toggleSidebar() {
      this.$emit('update-about', false)
    }
  }
}
</script>

<style scoped>
.sidebar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}
.show-sidebar {
  transform: translateY(0);
}
</style>
