<template>
    <!-- Hero Header -->
    <div
    class="flex justify-center items-center text-center main font-bold pt-20 pb-5 text-[#141733] hero-header mt-[20px] md:mt-[40px] lg:mt-[55px] xl:mt-[90px] cursor-default font-barlow"
  >
  {{ $t('servicePage.ourCoreService') }}
  </div>
</template>

<script>
    export default {}
</script>

<style scoped lang="postcss">
.hero-header {
  @apply text-[45px] md:text-[65px] lg:text-[65px] xl:text-[90px] h-60;
}
</style>