<template>
  <div class="container mx-auto pt-10 pb-24 px-[30px] font-barlow">
    <div class="flex flex-col justify-center items-center text-center gap-y-10">
      <h1 class="always-ready leading-none">
        {{ $t('aboutus.alwaysReady') }}
      </h1>
      <p class="always-ready-content">
        {{ $t('servicePage.focus1') }} <br> {{ $t('servicePage.focus2') }}
      </p>
      <router-link :to="'/contact-us'" class="w-fit">
        <button
          class="contact-btn"
        >
          {{ $t('aboutus.appointment') }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="postcss">
.always-ready {
  @apply text-[22px] md:text-[36px] xl:text-[45px] font-black cursor-default;
}

.always-ready-content {
  @apply text-[#8e8f9a] text-[14px] md:text-[18px] xl:text-[16px] cursor-default;
}

.contact-btn {
  @apply text-[17.5px] bg-accent text-white font-bold w-fit px-[20px] py-[15px] rounded-3xl border-2 border-accent hover:text-accent hover:bg-white transition-all duration-300;
}
</style>
