<template>
  <!-- Hero Header -->
  <div class="relative main smooth-scroll cursor-default font-barlow">
    <img
      src="@/assets/images/backup-recovery/data-center.png"
      class="object-cover object-bottom sm:h-[470px] md:h-[436px] lg:h-[595px] w-full mt-20"
    />
    <div class="container mx-auto">
      <div
        class="centered-element flex flex-row justify-center items-center space-x-8 p-10"
      >
        <div class="flex flex-col w-fit gap-y-8 xl:w-[420px]">
          <h1 class="w-fit heading1 font-bold text-white leading-none">
            {{ $t('backupRecoveryPage.title') }}
          </h1>
          <div class="w-[200px] h-[2px] bg-white"></div>
          <h3 class="w-fit description text-white">
            {{ $t('backupRecoveryPage.description') }}
          </h3>
        </div>
        <div class="flex flex-col w-fit gap-y-10 xl:w-1/5">
          <h1
            class="w-fit heading2 font-bold text-yellow-500 leading-none"
          >
            {{ $t('backupRecoveryPage.planDescription') }}
          </h1>
          <router-link :to="'/contact-us'" class="w-full">
            <button
              class="rounded-3xl text-[16px] font-bold p-3 lg:p-3 bg-accent border-4 border-accent hover:bg-accent text-white text-sm hover:transition-all"
            >
              {{ $t('backupRecoveryPage.startNow') }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="postcss">
.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading1 {
  @apply text-[20px] sm:text-[40px] md:text-[40px] lg:text-[60px];
}
.heading2 {
  @apply text-[30px] sm:text-[50px] md:text-[55px] lg:text-[75px];
}
.description {
  @apply text-[10px] sm:text-[14px] md:text-[16px] lg:text-[20px];
}
</style>
