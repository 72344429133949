<template>
  <div class="bg-primary">
    <div id="focus" class="container mx-auto py-10 px-[30px] cursor-default font-barlow">
      <div class="flex flex-col text-center gap-y-5">
        <h2 class="main">{{ $t('aboutus.advantageFocus') }}</h2>
        <p class="description text-white">
          {{ $t('aboutus.design') }}
        </p>
      </div>

      <div class="py-10">
        <div class="grid grid-rows-3 grid-cols-1 md:grid-rows-2 md:grid-cols-2 lg:grid-rows-1 lg:grid-cols-4 gap-10">
          <div v-for="item in data" :key="item.id" class="text-center flex flex-col items-center">
            <img :src="getImage(item.imageLink)" alt="" class="h-[150px] w-[150px]" />
            <div class="flex flex-col gap-y-3">
              <h2 class="title">
                {{ item.title }}
              </h2>
              <p class="description-2">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>

<script>
export default {
  name: 'Section3Component',
  computed:{
    data() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).aboutus.roles;
    }
  },
  methods: {
    getImage(imageLink) {
      return require(`@/${imageLink}`)
    }
  }
}
</script>

<style scoped lang="postcss">
.main {
  @apply text-white font-extrabold text-[20px] sm:text-[22px] md:text-[36px] xl:text-[45px];
}

.description {
  @apply text-[14px] sm:text-[14px] md:text-[16px];
}

.title {
  @apply text-white font-bold text-wrap text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px];
}

.description-2 {
  @apply text-gray-200 text-wrap text-[11px] sm:text-[11px] md:text-[11px] xl:text-[14px];
}
</style>
